import React, { useCallback, useMemo, useState } from 'react';
import ceil from 'lodash/ceil';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '../AnalyticsProvider';
import useGalleries from '../../hooks/useGalleries';
import { DataExplorerScrollableContainer } from '../DataExplorer';
import Spinner from './Spinner';
import Error from './Error';
import { Gallery, GalleryItemOrientation } from '../../types';
import { TranslationKey } from '../../translations/types';

const GalleriesListList = styled.div`
  display: flex;
  height: 100%;
`;

const GalleriesListControl = styled.div<{ prev?: boolean; next?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 15px 0 0;
`;

const GalleriesListNavButton = styled.button<{ prev?: boolean; next?: boolean }>`
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.dusty};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    margin-top: -3px;
    margin-left: -5px;
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.black};
  }

  ${({ prev }) =>
    prev &&
    `
    &:after {
      margin-top: -4px;
      margin-left: -3px;
      transform: rotate(-135deg);
    }
  `}

  ${({ next }) =>
    next &&
    `
    &:after {
      transform: rotate(45deg);
    }
  `}
`;

const GalleriesListItem = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
`;

const GalleriesListThumb = styled.button<{ orientation?: GalleryItemOrientation }>`
  position: relative;
  display: inline-flex;
  width: 84px;
  height: 100%;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: transparent;

  ${({ orientation }) =>
    orientation === GalleryItemOrientation.PORTRAIT &&
    `
    width: 58px;
  `};

  ${({ orientation }) =>
    orientation === GalleryItemOrientation.LANDSCAPE &&
    `
    width: 83px;
    height: 58px;
  `};

  ${({ orientation }) =>
    orientation === GalleryItemOrientation.SQUARE &&
    `
    width: 58px;
    height: 58px;
  `};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.alto};
    z-index: -1;
  }
`;

interface GalleriesListProps {
  isActive: boolean;
  activeCategoryId: string;
  onSelectGallery: (gallery: Gallery) => void;
}

const GALLERIES_PER_PAGE = 25;

const GalleriesList: React.FC<GalleriesListProps> = ({ isActive, activeCategoryId, onSelectGallery }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalytics();

  const [page, setPage] = useState(1);
  const goToNextPage = useCallback(() => setPage((prevPage) => prevPage + 1), []);
  const goToPrevPage = useCallback(() => setPage((prevPage) => Math.max(prevPage - 1, 1)), []);

  const galleriesState = useGalleries(activeCategoryId, page);

  const total = galleriesState?.data?.total ?? 0;

  const pagesCount = useMemo(() => ceil(total / GALLERIES_PER_PAGE), [total]);

  const isFirstPage = useMemo(() => page === 1, [page]);

  const isLastPage = useMemo(() => page === pagesCount, [page, pagesCount]);

  return (
    <DataExplorerScrollableContainer isHidden={!isActive} key={page}>
      {galleriesState.isLoading && <Spinner />}

      {galleriesState.isSuccess && (
        <GalleriesListList>
          {!isFirstPage && (
            <GalleriesListControl prev>
              <GalleriesListNavButton type="button" onClick={goToPrevPage} aria-label={t(TranslationKey.back)} prev />
            </GalleriesListControl>
          )}

          {galleriesState.data.galleries.map((gallery) => {
            const postersCount = gallery.items.length;
            const firstPoster = gallery.items[0];
            const orientation = postersCount === 1 ? firstPoster?.orientation : undefined;

            return (
              <GalleriesListItem key={gallery.id}>
                <GalleriesListThumb
                  type="button"
                  style={{ backgroundImage: `url("${gallery.thumbnail}")` }}
                  aria-label={gallery.title}
                  orientation={orientation}
                  onClick={() => {
                    onSelectGallery(gallery);

                    analyticsService.event.selectGallery(gallery.id);
                  }}
                />
              </GalleriesListItem>
            );
          })}

          {!isLastPage && (
            <GalleriesListControl next>
              <GalleriesListNavButton
                type="button"
                onClick={goToNextPage}
                aria-label={t(TranslationKey.forward)}
                next
              />
            </GalleriesListControl>
          )}
        </GalleriesListList>
      )}

      {galleriesState.isError && <Error />}
    </DataExplorerScrollableContainer>
  );
};

export default GalleriesList;
