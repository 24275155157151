import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { PosterSize } from '../../types';
import CloseButton from '../CloseButton';
import { TranslationKey } from '../../translations/types';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 151px;
  padding: 20px 22px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Title = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Button = styled.button<{ isActive: boolean; isDefault: boolean }>`
  display: flex;
  margin: 0 12px 0 0;
  padding: 12px 13px;
  appearance: none;
  outline: none;
  font-size: 14px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => rgba(theme.colors.shuttleGray, 0.1)};
  border-radius: 18px;
  border: none;

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.colors.white};
    background: ${rgba(theme.colors.shuttleGray, 1)};
  `};

  ${({ isDefault }) =>
    isDefault &&
    `
    text-decoration: underline;
  `};
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: -22px;
  right: 2px;
`;

interface SizesExplorerProps {
  compositionId: string;
  currentSize: PosterSize;
  sizes: PosterSize[];
  defaultSize?: PosterSize;
  onChangeSize: (compositionId: string, size: PosterSize) => void;
  onClose: () => void;
}

const SizesExplorer: React.FC<SizesExplorerProps> = ({
  compositionId,
  currentSize,
  sizes,
  defaultSize,
  onChangeSize,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t(TranslationKey.changeSize)}</Title>

      <List>
        {sizes.map((size) => {
          const isActive = size.width === currentSize.width && size.height === currentSize.height;
          const isDefault = defaultSize
            ? size.width === defaultSize.width && size.height === defaultSize.height
            : false;

          return (
            <Button
              key={`${size.width}x${size.height}`}
              onClick={() => onChangeSize(compositionId, size)}
              isActive={isActive}
              isDefault={isDefault}
            >{`${size.width}x${size.height}`}</Button>
          );
        })}
      </List>

      <CloseButtonContainer>
        <CloseButton onClick={onClose} label={t(TranslationKey.close)} />
      </CloseButtonContainer>
    </Container>
  );
};

export default SizesExplorer;
