import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../translations/types';
import interiorHintThumbnail from '../../assets/images/vis-interior-hint.gif';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
`;

const Thumbnail = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  img {
    display: block;
    width: 100%;
    max-height: 100%;
  }
`;

const Title = styled.div`
  font-size: 17px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

const InteriorHint = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Thumbnail>
        <img src={interiorHintThumbnail} alt="" />
      </Thumbnail>

      <Title>{t(TranslationKey.interiorHint)}</Title>
    </Container>
  );
};

export default InteriorHint;
