import styled, { css } from 'styled-components';

const shared = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  margin: 0;
  padding: 0 30px;
  appearance: none;
  font-size: 19px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.black};

  &:disabled {
    opacity: 0.6;
  }
`;

const Button = styled.button`
  ${shared};
`;

export const LinkedButton = styled.a`
  ${shared};

  text-decoration: none;
`;

export default Button;
