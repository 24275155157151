import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components';

const mineShaft = '#333333';
const scorpion = rgba(mineShaft, 0.8);
const doveGray = rgba(mineShaft, 0.7);
const gallery = rgba(mineShaft, 0.1);
const athensGray = '#FCFCFD';
const iron = '#DEE1E5';
const cloudBurst = '#243A53';
const porcelain = rgba(cloudBurst, 0.1);
const alabaster = '#F8F8F8';
const black = '#000000';
const alto = rgba(black, 0.1);
const dusty = rgba(black, 0.4);
const silver = rgba(black, 0.2);
const carnation = '#F8504C';
const alizarinCrimson = '#DF211C';
const persimmon = '#FF5453';
const roseWhite = '#FFFBFB';
const white = '#FFFFFF';
const shuttleGray = '#5C6B7E';
const breakerBay = '#599296';
const casal = '#29686B';
const dustyGray = '#979797';
const blueRibbon = '#006BFF';
const doubleSpanishWhite = '#EBDCC6';
const cumin = '#904823';
const teak = '#B3905F';

const transition = '0.3s ease';

const arrow = `
  content: '';
  position: relative;
  display: inline-flex;
  width: 0.43em;
  height: 0.43em;
  margin-top: -0.215em;
  margin-left: 0.250em;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: currentColor;
  transform: rotate(45deg);
`;

const theme: DefaultTheme = {
  fontFamily: {
    circe: "'TildaSans', Helvetica, Arial",
  },
  colors: {
    mineShaft,
    scorpion,
    doveGray,
    gallery,
    porcelain,
    alabaster,
    black,
    carnation,
    alizarinCrimson,
    white,
    alto,
    dusty,
    athensGray,
    iron,
    silver,
    shuttleGray,
    breakerBay,
    casal,
    dustyGray,
    persimmon,
    roseWhite,
    blueRibbon,
    doubleSpanishWhite,
    cumin,
    teak,
  },
  breakpoints: {
    // XXS
    XS: 'max-width: 359px',
    S: 'max-width: 767px',
    // M
    L: 'max-width: 1024px',
    XL: 'max-width: 1200px',
    XXL: 'max-width: 1260px',
  },
  hover: 'not all and (pointer: coarse)',
  transition,
  headerHeight: 54,
  arrow,
};

export default theme;
