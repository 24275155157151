interface CompositionOffset {
  x: number;
  y: number;
}

type GetCompositionOffset = () => CompositionOffset;

const getCompositionOffsetSelector = (): GetCompositionOffset => {
  const offsetA = 0;
  const offsetB = 20;

  let counter = 0;

  const getCompositionOffset = () => {
    const offset = counter % 2 === 0 ? offsetA : offsetB;

    counter += 1;

    const result = { x: offset, y: offset };

    return result;
  };

  return getCompositionOffset;
};

export default getCompositionOffsetSelector;
