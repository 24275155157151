import { UseQueryResult, useQuery as useReactQuery } from 'react-query';
import useQuery from './useQuery';
import useGallery from './useGallery';
import { Gallery } from '../types';

const fetchNonExistentGallery = async () => undefined;

const useNonExistentGallery = () =>
  useReactQuery(['nonExistentGallery'], () => fetchNonExistentGallery(), { refetchOnWindowFocus: false });

interface Query {
  galleryId?: string;
}

const usePreselectedGallery = (): UseQueryResult<Gallery | undefined, unknown> => {
  const { galleryId = '' } = useQuery<Query>();

  const galleryFetchingState = useGallery(galleryId);

  const nonExistentGalleryFetchingState = useNonExistentGallery();

  if (galleryId === '') return nonExistentGalleryFetchingState;

  return galleryFetchingState;
};

export default usePreselectedGallery;
