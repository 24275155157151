import React from 'react';

const InteriorEditIcon: React.FC = () => (
  <svg width="26" height="25" viewBox="0 0 26 25">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path d="M13 3.55c.249 0 .45.201.45.45 0 .22-.16.405-.37.443L13 4.45H5.8c-.701 0-1.278.536-1.344 1.22l-.006.13v14.4c0 .701.536 1.278 1.22 1.344l.13.006h14.4c.701 0 1.278-.536 1.344-1.22l.006-.13V13c0-.249.201-.45.45-.45.22 0 .405.16.443.37l.007.08v7.2c0 1.19-.926 2.166-2.096 2.245l-.154.005H5.8c-1.19 0-2.166-.926-2.245-2.096L3.55 20.2V5.8c0-1.19.926-2.166 2.096-2.245L5.8 3.55H13zm6.246-.604c.527-.528 1.38-.528 1.908 0l2.328 2.327c.528.528.528 1.381 0 1.91l-8.364 8.363c-.084.084-.199.132-.318.132l-3.414-.001-.768.769c-.176.176-.46.176-.636 0-.154-.154-.173-.391-.058-.566l.058-.07.767-.769.001-3.413c0-.09.027-.177.076-.25l.056-.069zm-1.56 2.831l-6.037 6.037v2.963h2.964l6.036-6.037-2.963-2.963zm2.832-2.195c-.176-.176-.46-.176-.636 0l-1.56 1.559 2.963 2.963 1.56-1.558c.133-.132.166-.325.1-.488l-.042-.078-.057-.07z" transform="translate(-292.000000, -568.000000) translate(292.000000, 568.000000)" />
        </g>
      </g>
    </g>
  </svg>
);

export default InteriorEditIcon;
