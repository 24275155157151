import React, { useEffect, useRef } from 'react';
import { Category } from '../../types';

const useBreadcrumbsScroll = (path: Category[]): React.RefObject<HTMLDivElement> => {
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  // scroll breadcrumbs container
  useEffect(() => {
    const node = breadcrumbsRef.current;

    if (!node) return;

    node.scrollLeft = node.scrollWidth - node.clientWidth;
  }, [path, breadcrumbsRef]);

  return breadcrumbsRef;
};

export default useBreadcrumbsScroll;
