import head from 'lodash/head';
import { GalleryItemSize, LinkedPoster } from '../../types';

const posterSizeIndex = 1; // We always want to use second size to make posters not so small on the scene

const getPosterSize = (poster: LinkedPoster): GalleryItemSize | undefined => {
  const { defaultSize } = poster;

  if (defaultSize) return defaultSize;

  const firstSize = head(poster.sizes);

  const size = poster.sizes[posterSizeIndex];

  const result = size || firstSize;

  return result;
};

export default getPosterSize;
