import styled from 'styled-components';
import React from 'react';
import Preloader from '../Preloader';

const SpinnerContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Spinner: React.FC = () => {
  return (
    <SpinnerContainer>
      <Preloader info />
    </SpinnerContainer>
  );
};

export default Spinner;
