import { v4 as uuidV4 } from 'uuid';

export enum GroupFrame {
  Black = 'black',
  White = 'white',
  DoubleSpanishWhite = 'doubleSpanishWhite',
  Hampton = 'hampton',
  Silver = 'silver',
  Redwood = 'redwood',
  Cowboy = 'cowboy',
  Parchment = 'parchment',
  None = 'none',
}

export const getHexColorByFrame = (frame: GroupFrame): string => {
  switch (frame) {
    case GroupFrame.Black:
      return '#000000';
    case GroupFrame.White:
      return '#FFFFFF';
    case GroupFrame.DoubleSpanishWhite:
      return '#EBDCC6';
    case GroupFrame.Hampton:
      return '#E2D5A5';
    case GroupFrame.Silver:
      return '#BBBBBB';
    case GroupFrame.Redwood:
      return '#681E0B';
    case GroupFrame.Cowboy:
      return '#51322B';
    case GroupFrame.Parchment:
      return '#F0E5CC';
    case GroupFrame.None:
      return 'transparent';
  }
};

export enum GalleryItemOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
  SQUARE = 'square',
}

export interface GalleryItemSize {
  width: number;
  height: number;
}

export interface Poster {
  id: string;
  itemId: string;
  title: string;
  thumbnail: string;
  orientation: GalleryItemOrientation;
  sizes: GalleryItemSize[];
  defaultSize?: GalleryItemSize;
}

export const buildPoster = (poster: Poster): Poster => poster;

export interface Gallery {
  id: string;
  galleryId: string;
  tenantId: string;
  title: string;
  thumbnail: string;
  categories: string[];
  link: string;
  items: Poster[];
}

export const buildGallery = (gallery: Gallery): Gallery => gallery;

export interface LinkedPoster extends Poster {
  link: string;
}

export const buildLinkedPoster = (poster: Poster, link: string): LinkedPoster => {
  const newPoster = { ...poster, link };

  return newPoster;
};

export interface PosterSize {
  width: number;
  height: number;
}

export interface Group {
  id: string;
  transform: string;
  content: string;
  width: number;
  height: number;
  posterSize: PosterSize;
  poster?: LinkedPoster;
  frame?: GroupFrame;
}

export interface CompositionTransform {
  x: number;
  y: number;
  scale: number;
  initialX?: number;
  initialY?: number;
  initialScale?: number;
}

export interface Composition extends CompositionTransform {
  id: string;
  width: number;
  height: number;
  groups: Group[];
  tenantId: string;
}

export interface NewComposition extends CompositionTransform {
  id: string;
  size: PosterSize;
  poster: LinkedPoster;
  frame?: GroupFrame;
}

interface BuildNewCompositionProps {
  id?: string;
  x?: number;
  y?: number;
  scale?: number;
  initialX?: number;
  initialY?: number;
  initialScale?: number;
  size: PosterSize;
  poster: LinkedPoster;
  frame?: GroupFrame;
}

export const buildNewComposition = (props: BuildNewCompositionProps): NewComposition => {
  const {
    id = uuidV4(),
    x = 0,
    y = 0,
    scale = 1,
    initialX = 0,
    initialY = 0,
    initialScale = 1,
    size,
    poster,
    frame,
  } = props;

  const composition = {
    id,
    x,
    y,
    scale,
    initialX,
    initialY,
    initialScale,
    size,
    poster,
    frame,
  };

  return composition;
};

export const buildComposition = (props: Composition): Composition => {
  const { id = '', width = 0, height = 0, x = 0, y = 0, scale = 1, groups = [], tenantId = '' } = props;

  const composition = { id, width, height, x, y, scale, groups, tenantId };

  return composition;
};

export interface Category {
  id: string;
  categoryId: string;
  title: string;
  slug: string;
  isRoot: boolean;
  parentId: string;
  tenantId: string;
  createdAt: string;
}

export const buildCategory = (props: Partial<Category>): Category => {
  const {
    id = '',
    categoryId = '',
    title = '',
    slug = '',
    isRoot = false,
    parentId = '',
    tenantId = '',
    createdAt = new Date().toISOString(),
  } = props;

  const category = {
    id,
    categoryId,
    title,
    slug,
    isRoot,
    parentId,
    tenantId,
    createdAt,
  };

  return category;
};

export interface CompositionsFilter {
  id: string;
  title: string;
  value: number;
}

export enum CheckoutType {
  BuiltIn = 'BUILTIN',
  External = 'EXTERNAL',
}

interface BaseCheckout {
  type: CheckoutType;
}

interface BuiltInCheckout extends BaseCheckout {
  type: CheckoutType.BuiltIn;
}

export const buildBuiltInCheckout = (): BuiltInCheckout => ({ type: CheckoutType.BuiltIn });

interface ExternalCheckout extends BaseCheckout {
  type: CheckoutType.External;
  checkoutUrl: string;
}

export const buildExternalCheckout = (checkoutUrl: string): ExternalCheckout => ({
  type: CheckoutType.External,
  checkoutUrl,
});

type Checkout = BuiltInCheckout | ExternalCheckout;

export interface Tenant {
  id: string;
  name: string;
  logo: string;
  link: string;
  isActive: boolean;
  frames: GroupFrame[];
  gaTrackingId?: string;
  checkout?: Checkout;
}

export enum InteriorOrientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
}

export interface InteriorTransform {
  x: number;
  y: number;
  scale: number;
}

export const buildInteriorTransform = (x = 0, y = 0, scale = 1): InteriorTransform => {
  const interiorTransform = { x, y, scale };

  return interiorTransform;
};

interface InteriorOriginalSize {
  width: number;
  height: number;
}

export const buildInteriorOriginalSize = (width: number, height: number): InteriorOriginalSize => {
  const size = { width, height };

  return size;
};

export interface Interior {
  preview: string;
  source: File;
  originalSize: InteriorOriginalSize;
  orientation: InteriorOrientation;
  transform: InteriorTransform;
  initialTransform: InteriorTransform;
}

interface BuildInteriorProps {
  preview: string;
  source: File;
  originalSize: InteriorOriginalSize;
  orientation: InteriorOrientation;
  transform: InteriorTransform;
  initialTransform: InteriorTransform;
}

export const buildInterior = (props: BuildInteriorProps): Interior => {
  const { preview, source, originalSize, orientation, transform, initialTransform } = props;

  const interior = { preview, source, originalSize, orientation, transform, initialTransform };

  return interior;
};
