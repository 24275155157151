import React, { createContext, useContext } from 'react';
import { Gallery } from '../../types';

const PreselectedGalleryContext = createContext<Gallery | null>(null);

interface PreselectedGalleryProps {
  gallery?: Gallery;
  children: React.ReactNode;
}

const PreselectedGalleryProvider: React.FC<PreselectedGalleryProps> = ({ children, gallery }) => {
  return <PreselectedGalleryContext.Provider value={gallery ?? null}>{children}</PreselectedGalleryContext.Provider>;
};

export const usePreselectedGallery = (): Gallery | undefined => {
  const ctx = useContext(PreselectedGalleryContext);

  if (!ctx) return undefined;

  return ctx;
};

export default PreselectedGalleryProvider;
