import { useQuery, UseQueryResult } from 'react-query';
import sortBy from 'lodash/sortBy';
import { Gallery, buildGallery, buildPoster } from '../types';
import { useTenantId } from '../components/TenantIdProvider';
import * as azureService from '../services/azureService';

const useGallery = (galleryId: string): UseQueryResult<Gallery, unknown> => {
  const tenantId = useTenantId();

  return useQuery<Gallery>([galleryId], () => azureService.getGallery({ tenantId, galleryId }), {
    enabled: !!galleryId,
    select: (gallery) => {
      const newGallery = buildGallery({
        ...gallery,
        items: gallery.items.map((item) => {
          const newItem = buildPoster({
            ...item,
            sizes: sortBy(item.sizes, (size) => size.width + size.height),
          });

          return newItem;
        }),
      });

      return newGallery;
    },
  });
};

export default useGallery;
