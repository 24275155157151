import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  display: flex;
  width: 155px;
  height: 25px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

interface LogoProps {
  link: string;
  imgUrl: string;
  onClick?: () => void;
}

const Logo: React.FC<LogoProps> = ({ link, imgUrl, onClick }) => {
  return (
    <Container href={link} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <img src={imgUrl} alt="" />
    </Container>
  );
};

export default Logo;
