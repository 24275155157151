import { useQuery, UseQueryResult } from 'react-query';
import {
  buildInterior,
  buildInteriorOriginalSize,
  buildInteriorTransform,
  Interior,
  InteriorOrientation,
} from '../types';
import * as browserDBService from '../services/browserDBService';

const buildPreview = (file: File): string => {
  const preview = URL.createObjectURL(file);

  return preview;
};

const fetchInterior = async () => {
  const interior = await browserDBService.interior.get();

  const initialInterior = buildInterior({
    preview: '',
    source: new File([], ''),
    originalSize: buildInteriorOriginalSize(0, 0),
    orientation: InteriorOrientation.Landscape,
    transform: buildInteriorTransform(),
    initialTransform: buildInteriorTransform(),
  });

  if (!interior) return initialInterior;

  const preview = buildPreview(interior.source);

  const newInterior = buildInterior({
    ...interior,
    preview,
    initialTransform: interior.transform,
  });

  return newInterior;
};

const useInterior = (): UseQueryResult<Interior, unknown> => {
  return useQuery<Interior>(['interior'], () => fetchInterior(), {
    refetchOnWindowFocus: false,
  });
};

export default useInterior;
