import React, { useMemo } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getHexColorByFrame, GroupFrame } from '../../types';
import DataExplorer, {
  DataExplorerHeader,
  DataExplorerContent,
  DataExplorerScrollableContainer,
} from '../DataExplorer';
import { useAnalytics } from '../AnalyticsProvider';
import { useTenant } from '../TenantProvider';
import { TranslationKey } from '../../translations/types';

const FramesDataExplorerContainer = styled.div``;

const FramesDataExplorerList = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const FramesDataExplorerItem = styled.div`
  height: 71px;
  padding-right: 22px;
`;

const FramesDataExplorerFrame = styled.button<{ frameColor: GroupFrame }>`
  position: relative;
  display: flex;
  width: 58px;
  height: 71px;
  margin: 0;
  padding: 0;
  appearance: none;
  border-width: 4px;
  border-style: solid;
  border-color: transparent;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.24), inset 1px 1px 3px 0 rgba(0, 0, 0, 0.36);

  border-color: ${({ frameColor }) => getHexColorByFrame(frameColor)};

  ${({ frameColor, theme }) =>
    frameColor === GroupFrame.None &&
    `
    border-color: transparent;
    background-color: ${rgba(theme.colors.shuttleGray, 0.1)};
    box-shadow: none;
    
    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${theme.colors.mineShaft};
      opacity: 0.8;
    }
    
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  `};
`;

interface FramesDataExplorerProps {
  onSelectFrame: (frame: GroupFrame) => void;
  onClose: () => void;
}

const FramesDataExplorer: React.FC<FramesDataExplorerProps> = ({ onSelectFrame, onClose }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantFrames = useMemo(() => tenant.frames ?? [], [tenant]);
  const allFrames = useMemo(() => [...tenantFrames, GroupFrame.None], [tenantFrames]);
  const analyticsService = useAnalytics();

  return (
    <FramesDataExplorerContainer>
      <DataExplorer
        onMount={analyticsService.event.openFramesExplorer}
        onUnMount={analyticsService.event.closeFramesExplorer}
      >
        <DataExplorerHeader onBack={onClose}>{t(TranslationKey.frameColor)}</DataExplorerHeader>

        <DataExplorerContent>
          <DataExplorerScrollableContainer>
            <FramesDataExplorerList>
              {allFrames.map((frame) => {
                const selectFrame = () => {
                  onSelectFrame(frame);

                  analyticsService.event.selectFrame(frame);
                };

                return (
                  <FramesDataExplorerItem key={frame}>
                    <FramesDataExplorerFrame
                      type="button"
                      aria-label={frame}
                      frameColor={frame}
                      onClick={selectFrame}
                    />
                  </FramesDataExplorerItem>
                );
              })}
            </FramesDataExplorerList>
          </DataExplorerScrollableContainer>
        </DataExplorerContent>
      </DataExplorer>
    </FramesDataExplorerContainer>
  );
};

export default FramesDataExplorer;
