import { useCallback, useMemo, useState } from 'react';
import { Category } from '../../types';

interface UseBreadcrumbsResult {
  onResetPath: () => void;
  onIncrementPath: (category: Category) => void;
  onDecrementPath: (category: Category) => void;
  path: Category[];
}

const useBreadcrumbs = (): UseBreadcrumbsResult => {
  const [path, setPath] = useState<Category[]>([]);
  const onResetPath = useCallback(() => setPath([]), []);
  const onIncrementPath = useCallback((category: Category) => setPath((prev) => [...prev, category]), []);
  const onDecrementPath = useCallback((category: Category) => {
    setPath((prev) => {
      const categoryIndex = prev.findIndex((categoryItem) => categoryItem.id === category.id);

      const result = prev.filter((categoryItem, index) => index <= categoryIndex);

      return result;
    });
  }, []);

  const result = useMemo(
    () => ({
      path,
      onResetPath,
      onIncrementPath,
      onDecrementPath,
    }),
    [path, onResetPath, onIncrementPath, onDecrementPath],
  );

  return result;
};

export default useBreadcrumbs;
