import { useQuery, UseQueryResult } from 'react-query';
import { useTenantId } from '../components/TenantIdProvider';
import { getGalleries, GetGalleriesResponse } from '../services/azureService';

const useGalleries = (categoryId: string, page: number): UseQueryResult<GetGalleriesResponse, unknown> => {
  const tenantId = useTenantId();

  return useQuery<GetGalleriesResponse>([categoryId, page], () => getGalleries({ tenantId, categoryId, page }), {
    enabled: !!categoryId,
  });
};

export default useGalleries;
