import { useCallback, useState } from 'react';
import { Gallery } from '../../types';
import { usePreselectedGallery } from '../PreselectedGalleryProvider';

type QueryFunction = () => Gallery | undefined;

const useLimitedPreselectedGallery = (): QueryFunction => {
  const preselectedGallery = usePreselectedGallery();

  const [queryCount, setQueryCount] = useState(0);

  const getPreselectedGallery = useCallback(() => {
    if (queryCount > 0) return undefined;

    setQueryCount((prev) => prev + 1);

    return preselectedGallery;
  }, [queryCount, preselectedGallery]);

  return getPreselectedGallery;
};

export default useLimitedPreselectedGallery;
