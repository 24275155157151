import { useCallback, useEffect, useMemo } from 'react';

interface UseScrollCacheResult {
  setScrollCache: (key: string, value: number) => void;
  getScrollCache: (key: string) => number | undefined;
}

const useScrollCache = (): UseScrollCacheResult => {
  const scrollCache = useMemo(() => new Map(), []);
  const setScrollCache = useCallback((key: string, value: number) => scrollCache.set(key, value), [scrollCache]);
  const getScrollCache = useCallback((key) => scrollCache.get(key), [scrollCache]);
  const clearScrollCache = useCallback(() => scrollCache.clear(), [scrollCache]);

  useEffect(() => {
    return () => clearScrollCache();
  }, [clearScrollCache]);

  const result = useMemo(() => ({ setScrollCache, getScrollCache }), [setScrollCache, getScrollCache]);

  return result;
};

export default useScrollCache;
