import React, { useCallback, useMemo, useRef, useState } from 'react';
import { buildInterior, buildInteriorTransform, Interior, InteriorOrientation, InteriorTransform } from '../../types';
import { useAnalytics } from '../AnalyticsProvider';
import { useInterior as useInitialInterior } from '../InteriorProvider';
import * as browserDbService from '../../services/browserDBService';

interface Dimensions {
  width: number;
  height: number;
}

const getImageDimensions = (file: File): Promise<Dimensions> =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;

      resolve({ width, height });
    };

    img.onerror = () => {
      reject(new Error('There was some problem with the image.'));
    };

    const src = URL.createObjectURL(file);

    img.src = src;
  });

const getOrientation = (width: number, height: number): InteriorOrientation => {
  if (width > height) return InteriorOrientation.Landscape;

  if (width < height) return InteriorOrientation.Portrait;

  return InteriorOrientation.Portrait;
};

const buildPreview = (file: File): string => {
  const preview = URL.createObjectURL(file);

  return preview;
};

type InteriorUploadingState = 'idle' | 'requested' | 'finished' | 'failed';

interface UseInteriorResult {
  interior: Interior;
  fileFieldRef: React.RefObject<HTMLInputElement>;
  onChangeInterior: (interior: Interior) => void;
  onTransformInterior: (data: InteriorTransform) => void;
  onUploadInterior: () => void;
  onUpdateInterior: () => void;
  onConfirmInterior: (onAfterConfirm?: () => void) => void;
  onUploadFile: (event: React.ChangeEvent<HTMLInputElement>, onAfterUpload?: () => void) => void;
}

const useInterior = (): UseInteriorResult => {
  const analyticsService = useAnalytics();

  const fileFieldRef = useRef<HTMLInputElement>(null);

  const [, setInteriorUploadingState] = useState<InteriorUploadingState>('idle');
  const uploadInteriorRequest = useCallback(() => setInteriorUploadingState('requested'), []);
  const uploadInteriorFinish = useCallback(() => setInteriorUploadingState('finished'), []);
  const uploadInteriorFail = useCallback(() => setInteriorUploadingState('failed'), []);

  const initialInterior = useInitialInterior();

  const [interior, setInterior] = useState<Interior>(initialInterior);

  const onChangeInterior = useCallback((newInterior: Interior) => {
    setInterior(newInterior);

    browserDbService.interior.set(newInterior);
  }, []);

  const onTransformInterior = useCallback((data: InteriorTransform) => {
    setInterior((prevInterior) => {
      const newInterior = buildInterior({ ...prevInterior, transform: data });

      browserDbService.interior.set(newInterior);

      return newInterior;
    });
  }, []);

  const onOpenFileBrowser = useCallback(() => {
    const node = fileFieldRef.current;

    if (!node) return;

    node.click();
  }, [fileFieldRef]);

  const onUploadInterior = useCallback(() => {
    onOpenFileBrowser();

    analyticsService.event.uploadInterior();
  }, [onOpenFileBrowser, analyticsService]);

  const onUpdateInterior = useCallback(() => {
    onOpenFileBrowser();

    analyticsService.event.updateInterior();
  }, [onOpenFileBrowser, analyticsService]);

  const onConfirmInterior = useCallback((onAfterConfirm?: () => void) => {
    onAfterConfirm?.();
  }, []);

  const onUploadFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, onAfterUpload?: () => void) => {
      uploadInteriorRequest();

      try {
        const file = event.currentTarget.files?.[0];

        if (!file) {
          uploadInteriorFail();

          return;
        }

        const originalSize = await getImageDimensions(file);

        const orientation = getOrientation(originalSize.width, originalSize.height);

        const preview = buildPreview(file);

        const newInterior = buildInterior({
          preview,
          source: file,
          originalSize,
          orientation,
          transform: buildInteriorTransform(),
          initialTransform: buildInteriorTransform(),
        });

        onChangeInterior(newInterior);

        uploadInteriorFinish();

        onAfterUpload?.();
      } catch (error) {
        uploadInteriorFail();
      }
    },
    [uploadInteriorRequest, uploadInteriorFinish, uploadInteriorFail, onChangeInterior],
  );

  const result = useMemo(
    () => ({
      interior,
      fileFieldRef,
      onChangeInterior,
      onTransformInterior,
      onUploadInterior,
      onUpdateInterior,
      onConfirmInterior,
      onUploadFile,
    }),
    [
      interior,
      fileFieldRef,
      onChangeInterior,
      onTransformInterior,
      onUploadInterior,
      onUpdateInterior,
      onConfirmInterior,
      onUploadFile,
    ],
  );

  return result;
};

export default useInterior;
