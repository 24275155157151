import { useQuery, UseQueryResult } from 'react-query';
import { Tenant } from '../types';
import { useTenantId } from '../components/TenantIdProvider';
import * as azureService from '../services/azureService';

const useTenant = (): UseQueryResult<Tenant, unknown> => {
  const tenantId = useTenantId();

  return useQuery<Tenant>(['settings'], () => azureService.getTenant({ tenantId }), {
    refetchOnWindowFocus: false,
  });
};

export default useTenant;
