import { useState, useEffect, useMemo, useCallback } from 'react';
import throttle from 'lodash/throttle';

interface SceneSize {
  width: number;
  height: number;
}

const SCENE_WIDTH = 355;

const getSceneHeight = (offset: number): number => {
  const result = window.innerHeight - offset;

  return result;
};

const useSceneSize = (offset: number = 0) => {
  const [sceneSize, setSceneSize] = useState<SceneSize>({ width: 0, height: 0 });

  const updateSceneSize = useCallback(() => {
    const height = getSceneHeight(offset);

    setSceneSize({ width: SCENE_WIDTH, height });
  }, [offset]);

  const updateSceneSizeHandler = useMemo(() => throttle(updateSceneSize, 100), [updateSceneSize]);

  useEffect(() => {
    window.addEventListener('resize', updateSceneSizeHandler);

    updateSceneSizeHandler();

    return () => window.removeEventListener('resize', updateSceneSizeHandler);
  }, [updateSceneSizeHandler]);

  return sceneSize;
};

export default useSceneSize;
