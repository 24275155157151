import styled from 'styled-components';
import { rgba } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../translations/types';

const Container = styled.button<{ size: Size }>`
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background-color: transparent;

  svg {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    z-index: 1;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &:before {
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.athensGray};
    box-shadow: 0 0 2px ${({ theme }) => rgba(theme.colors.mineShaft, 0.6)};

    ${({ size }) =>
      size === 'small' &&
      `
      width: 20px;
      height: 20px;
    `}
  }
`;

type Size = 'small' | 'default';

interface RemoveButtonProps {
  onClick: () => void;
  label?: string;
  size?: Size;
}

const EditButton: React.FC<RemoveButtonProps> = ({ onClick, label, size = 'default' }) => {
  const { t } = useTranslation();

  return (
    <Container type="button" aria-label={label ?? t(TranslationKey.edit)} onTouchEnd={onClick} size={size}>
      <svg viewBox="0 0 12 12">
        <path d="M6.94.94a1.499 1.499 0 0 1 2.12 0l1.587 1.586a1.499 1.499 0 0 1 0 2.12L4.354 10.94a.5.5 0 0 1-.368.146l-2.712-.079-.455.378a.5.5 0 0 1-.702-.707l.064-.063.319-.264V7.586a.5.5 0 0 1 .095-.293l.051-.06zm-.936 2.347L1.5 7.792v2.222l2.298.066 4.533-4.533-2.327-2.26zm2.35-1.64a.499.499 0 0 0-.707 0l-.935.933 2.326 2.26.901-.9a.499.499 0 0 0 .102-.562l-.044-.076-.058-.07z" />
      </svg>
    </Container>
  );
};

export default EditButton;
