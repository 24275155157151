import React from 'react';

const InteriorFrameIcon: React.FC = () => (
  <svg width="26" height="25" viewBox="0 0 26 25">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path d="M21.01 2.55c1.14 0 2.07.93 2.07 2.07 0 .632-.284 1.21-.757 1.598l-.054.04v12.113l.054.04c.43.353.704.864.75 1.428l.007.171c0 1.14-.93 2.07-2.07 2.07-.632 0-1.21-.284-1.598-.757l-.041-.054H7.258l-.04.054c-.352.43-.863.704-1.427.75l-.171.007c-1.14 0-2.07-.93-2.07-2.07 0-.632.284-1.21.757-1.598l.052-.04V6.257l-.052-.039c-.43-.352-.704-.863-.75-1.427L3.55 4.62c0-1.14.93-2.07 2.07-2.07.632 0 1.21.284 1.598.757l.039.052h12.115l.04-.052c.352-.43.863-.704 1.427-.75zm0 .9c-.422 0-.8.224-1.013.587-.08.138-.228.223-.388.223H7.02c-.16 0-.308-.085-.388-.223-.212-.363-.59-.587-1.013-.587-.642 0-1.17.528-1.17 1.17 0 .422.224.8.587 1.013.138.08.223.228.223.388V18.61c0 .16-.085.308-.223.388-.363.212-.587.59-.587 1.013 0 .642.528 1.17 1.17 1.17.422 0 .8-.224 1.013-.587.08-.138.228-.223.388-.223H19.61c.16 0 .308.085.388.223.212.363.59.587 1.013.587.642 0 1.17-.528 1.17-1.17 0-.422-.224-.8-.587-1.013-.138-.08-.223-.228-.223-.388V6.02c0-.16.085-.308.223-.388.363-.212.587-.59.587-1.013 0-.642-.528-1.17-1.17-1.17zm-1.62 2.34c.249 0 .45.201.45.45v12.15c0 .249-.201.45-.45.45H7.24c-.249 0-.45-.201-.45-.45V6.24c0-.249.201-.45.45-.45zm-.451.899H7.689v11.25h11.25V6.689z" transform="translate(-137.000000, -568.000000) translate(137.000000, 568.000000)" />
        </g>
      </g>
    </g>
  </svg>
);

export default InteriorFrameIcon;
