import React from 'react';

const InteriorPosterIcon: React.FC = () => (
  <svg width="26" height="25" viewBox="0 0 26 25">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path d="M21 3.55c1.243 0 2.25 1.007 2.25 2.25v12.6c0 1.243-1.007 2.25-2.25 2.25H4.8c-1.243 0-2.25-1.007-2.25-2.25V5.8c0-1.243 1.007-2.25 2.25-2.25zm0 .9H4.8c-.746 0-1.35.604-1.35 1.35v12.6c0 .746.604 1.35 1.35 1.35l.245-.001 5.93-6.79 2.939 2.322 3.928-4.265 4.507 4.113.001-9.329c0-.702-.535-1.278-1.22-1.344L21 4.45zm-3.104 7.834l-3.885 4.22-2.921-2.308-4.85 5.553L21 19.75c.702 0 1.278-.535 1.344-1.22l.006-.13-.001-2.052-4.453-4.064zM7.5 6.25c1.243 0 2.25 1.007 2.25 2.25s-1.007 2.25-2.25 2.25S5.25 9.743 5.25 8.5 6.257 6.25 7.5 6.25zm0 .9c-.746 0-1.35.604-1.35 1.35 0 .746.604 1.35 1.35 1.35.746 0 1.35-.604 1.35-1.35 0-.746-.604-1.35-1.35-1.35z" transform="translate(-211.000000, -568.000000) translate(211.000000, 568.000000)" />
        </g>
      </g>
    </g>
  </svg>
);

export default InteriorPosterIcon;
