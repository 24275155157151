import React from 'react';

const AlertIcon: React.FC = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="23px"
    height="21px"
    viewBox="0 0 23 21"
  >
    <path
      d="M22.823,18.68L12.999,0.867c-0.266-0.536-0.808-0.869-1.413-0.869c-0.608,0-1.155,0.336-1.418,0.859L0.172,18.688
                      c-0.251,0.497-0.227,1.078,0.065,1.553C0.529,20.717,1.037,21,1.594,21h19.812c0.555,0,1.062-0.282,1.354-0.754
                      C23.053,19.774,23.08,19.196,22.823,18.68z M21.91,19.719C21.8,19.897,21.616,20,21.406,20H1.594c-0.21,0-0.395-0.104-0.505-0.282
                      c-0.11-0.18-0.119-0.391-0.024-0.579L11.05,1.326c0.104-0.206,0.304-0.329,0.536-0.329c0.227,0,0.42,0.117,0.527,0.333l9.824,17.812
                      C22.031,19.331,22.021,19.541,21.91,19.719z"
    />
    <path
      d="M11.5,15.9c0.553,0,1,0.447,1,1c0,0.552-0.447,1-1,1c-0.552,0-1-0.448-1-1C10.5,16.348,10.948,15.9,11.5,15.9z"
    />
    <path
      d="M11.464,7c-0.498,0-0.889,0.408-0.875,0.887l0.195,6.227C10.8,14.604,11.221,15,11.499,15c0,0-0.697,0,0,0l0,0
                      c0.368,0,0.679-0.408,0.693-0.887l0.19-6.227C12.397,7.397,12.018,7,11.508,7H11.464z"
    />
  </svg>
);

export default AlertIcon;
