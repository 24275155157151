import { useEffect } from 'react';
import { PanZoom } from 'panzoom';

interface UsePanZoomInitialPositionProps {
  initialX: number;
  initialY: number;
  initialZoom: number;
  instance?: PanZoom;
}

const usePanZoomInitialPosition = ({ instance, initialX, initialY, initialZoom }: UsePanZoomInitialPositionProps) => {
  useEffect(() => {
    if (!instance) return;

    setTimeout(() => {
      // for some unknown reason this works only inside setTimeout 🤷‍
      instance.moveTo(initialX, initialY);
      instance.zoomTo(initialX, initialY, initialZoom);
    }, 0);
  }, [instance, initialX, initialY, initialZoom]);
};

export default usePanZoomInitialPosition;
