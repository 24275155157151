import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { TranslationKey } from '../../translations/types';

const Controls = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 66px;
  padding: 0 10px;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const InteriorButton = styled(Button)<{ isBare?: boolean }>`
  height: 40px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.mineShaft};
  border: 1px solid currentColor;
  background-color: transparent;

  ${({ isBare }) =>
    isBare &&
    `
    padding-left: 5px;
    padding-right: 5px;
    border-color: transparent;
  `};
`;

interface InteriorControlsProps {
  onEdit: () => void;
  onSave: () => void;
}

const InteriorControls: React.FC<InteriorControlsProps> = ({ onEdit, onSave }) => {
  const { t } = useTranslation();

  return (
    <Controls>
      <Container>
        <InteriorButton onClick={onEdit} isBare>
          {t(TranslationKey.changePhoto)}
        </InteriorButton>

        <InteriorButton onClick={onSave}>{t(TranslationKey.next)}</InteriorButton>
      </Container>
    </Controls>
  );
};

export default InteriorControls;
