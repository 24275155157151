import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import chunk from 'lodash/chunk';
import orderBy from 'lodash/orderBy';
import Spinner from './Spinner';
import Error from './Error';
import GalleriesList from './GalleriesList';
import PostersList from './PostersList';
import DataExplorer, {
  DataExplorerContent,
  DataExplorerHeader,
  DataExplorerScrollableContainer,
} from '../DataExplorer';
import { Category, Gallery, Poster, LinkedPoster, buildCategory } from '../../types';
import useCategories from '../../hooks/useCategories';
import useBreadcrumbs from './useBreadcrumbs';
import useBreadcrumbsScroll from './useBreadcrumbsScroll';
import useScrollCache from './useScrollCache';
import { useAnalytics } from '../AnalyticsProvider';
import { TranslationKey } from '../../translations/types';

const PostersDataExplorerContainer = styled.div``;

const CategoriesListContainer = styled.div``;

const CategoriesListList = styled.div`
  display: flex;
  height: 100%;
`;

const CategoriesListGroup = styled.div`
  padding-right: 25px;
`;

const CategoriesListItem = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 13px 0;
  appearance: none;
  border: none;
  background-color: transparent;
`;

const CategoriesListItemTitle = styled.div`
  width: calc(100% - 18px);
  padding-left: 6px;
  font-size: 17px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  white-space: nowrap;
`;

const Breadcrumbs = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  left: 10px;
  right: 10px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Path = styled.button<{ isDisabled: boolean }>`
  position: relative;
  display: flex;
  margin: 0 15px 0 0;
  padding: 13px 0 10px;
  appearance: none;
  font-size: 15px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  background-color: transparent;
  border: none;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: 50%;
    margin-top: -2px;
    right: -10px;
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: currentColor;
    transform: rotate(45deg);
  }

  &:nth-last-child(-n + 1) {
    &:after {
      display: none;
    }
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    opacity: 0.7;
    color: ${theme.colors.mineShaft};
    pointer-events-none;
  `}
`;

const BackButton = styled.button`
  position: absolute;
  display: flex;
  width: 44px;
  height: 44px;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;

  &:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    left: 50%;
    top: 50%;
    margin-top: -7px;
    margin-left: -7px;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.black};
    transform: rotate(-45deg);
  }
`;

const GalleryTitle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 44px;
  padding-right: 15px;
`;

const GalleryTitleBox = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExplorerContainer = styled.div<{ isVisible: boolean }>`
  display: block;
  height: 100%;

  ${({ isVisible }) =>
    isVisible === false &&
    `
    display: none;
  `};
`;

const sortByCreationDate = (category: Category) => category.createdAt;

const rootCategory = buildCategory({
  id: '',
  categoryId: '',
  title: 'Категории',
  isRoot: true,
  parentId: '',
});

type ExplorerState = 'selectingCategory' | 'selectingGallery' | 'selectingPoster';

interface PostersDataExplorerProps {
  onPickPoster?: (groupPoster: LinkedPoster) => void;
  onClose: () => void;
}

const PostersDataExplorer: React.FC<PostersDataExplorerProps> = ({ onPickPoster, onClose }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalytics();

  const [explorerState, setExplorerState] = useState<ExplorerState>('selectingCategory');
  const selectCategory = useCallback(() => setExplorerState('selectingCategory'), []);
  const selectGallery = useCallback(() => setExplorerState('selectingGallery'), []);
  const selectPoster = useCallback(() => setExplorerState('selectingPoster'), []);

  const isSelectingCategory = explorerState === 'selectingCategory';
  const isSelectingGallery = explorerState === 'selectingGallery';
  const isSelectingPoster = explorerState === 'selectingPoster';

  const [activeGallery, setActiveGallery] = useState<Gallery | null>(null);
  const activateGallery = useCallback((gallery: Gallery) => setActiveGallery(gallery), []);

  const [activeCategory, setActiveCategory] = useState<Category>(rootCategory);
  const activateCategory = useCallback((category: Category) => setActiveCategory(category), []);

  const { path, onResetPath, onIncrementPath, onDecrementPath } = useBreadcrumbs();
  const breadcrumbsRef = useBreadcrumbsScroll(path);

  const onSelectPoster = useCallback(
    (gallery: Gallery, poster: Poster) => {
      if (!onPickPoster) return;

      const newPoster = { ...poster, link: gallery.link };

      onPickPoster(newPoster);
    },
    [onPickPoster],
  );

  const onSelectGallery = useCallback(
    (gallery: Gallery) => {
      activateGallery(gallery);

      const postersCount = gallery.items.length;

      if (postersCount === 1) {
        const firstPoster = gallery.items[0];

        onSelectPoster(gallery, firstPoster);

        analyticsService.event.selectPoster(firstPoster.id);

        return;
      }

      selectPoster();
    },
    [activateGallery, selectPoster, onSelectPoster, analyticsService],
  );

  const onDecrementBreadcrumbs = useCallback(
    (category: Category) => {
      onDecrementPath(category);
      activateCategory(category);
    },
    [onDecrementPath, activateCategory],
  );

  const onResetBreadcrumbs = useCallback(() => {
    onResetPath();
    activateCategory(rootCategory);
  }, [onResetPath, activateCategory]);

  const { setScrollCache, getScrollCache } = useScrollCache();
  const onScroll = useCallback(
    (scrollLeft: number) => setScrollCache(activeCategory.id, scrollLeft),
    [activeCategory, setScrollCache],
  );
  const activeCategoryScrollLeft = getScrollCache(activeCategory.id);

  const categoriesState = useCategories(activeCategory.id);
  const { isSuccess } = categoriesState;
  const categoriesCount = useMemo(() => {
    const categories = categoriesState.data ?? [];

    return categories.length;
  }, [categoriesState]);
  const isDeepestCategory = isSuccess && categoriesCount === 0;

  // transition machine to selectingCategory or selectingGallery state
  useEffect(() => {
    if (!isDeepestCategory) {
      selectCategory();

      return;
    }

    selectGallery();
  }, [isDeepestCategory, selectCategory, selectGallery]);

  return (
    <PostersDataExplorerContainer>
      <DataExplorer
        onMount={analyticsService.event.openPostersExplorer}
        onUnMount={analyticsService.event.closePostersExplorer}
      >
        <DataExplorerHeader onBack={onClose}>
          {!isSelectingPoster && (
            <Breadcrumbs ref={breadcrumbsRef}>
              <Path isDisabled={path.length === 0} onClick={onResetBreadcrumbs}>
                {t(TranslationKey.categories)}
              </Path>

              {path.map((pathItem, index) => {
                const isLastItem = index === path.length - 1;

                return (
                  <Path key={pathItem.id} isDisabled={isLastItem} onClick={() => onDecrementBreadcrumbs(pathItem)}>
                    {pathItem.title}
                  </Path>
                );
              })}
            </Breadcrumbs>
          )}

          {isSelectingPoster && (
            <GalleryTitle>
              <BackButton onClick={selectGallery} />

              <GalleryTitleBox>{activeGallery?.title}</GalleryTitleBox>
            </GalleryTitle>
          )}
        </DataExplorerHeader>

        <DataExplorerContent>
          <DataExplorerScrollableContainer
            isHidden={!isSelectingCategory}
            onScroll={onScroll}
            scrollLeft={activeCategoryScrollLeft}
          >
            <CategoriesListContainer>
              {categoriesState.isLoading && <Spinner />}

              {categoriesState.isSuccess && (
                <CategoriesListList>
                  {chunk(orderBy(categoriesState.data, sortByCreationDate, ['desc']), 2).map((group) => {
                    const category1 = group[0];
                    const category2 = group[1];

                    return (
                      <CategoriesListGroup key={`${category1?.id}-${category2?.id}`}>
                        {group.map((category) => {
                          const onClick = () => {
                            activateCategory(category);
                            onIncrementPath(category);

                            analyticsService.event.selectCategory(category.id);
                          };

                          return (
                            <CategoriesListItem key={category.id} type="button" onClick={onClick}>
                              <CategoriesListItemTitle>{category.title}</CategoriesListItemTitle>
                            </CategoriesListItem>
                          );
                        })}
                      </CategoriesListGroup>
                    );
                  })}
                </CategoriesListList>
              )}

              {categoriesState.isError && <Error />}
            </CategoriesListContainer>
          </DataExplorerScrollableContainer>

          <ExplorerContainer isVisible={isSelectingGallery}>
            <GalleriesList
              key={activeCategory.id}
              isActive={isSelectingGallery}
              activeCategoryId={activeCategory.id}
              onSelectGallery={onSelectGallery}
            />
          </ExplorerContainer>

          {isSelectingPoster && !!activeGallery && (
            <PostersList activeGallery={activeGallery} onSelectPoster={onSelectPoster} />
          )}
        </DataExplorerContent>
      </DataExplorer>
    </PostersDataExplorerContainer>
  );
};

export default PostersDataExplorer;
