import React, { createContext, useCallback, useContext, useMemo } from 'react';
import ReactGA from 'react-ga';

interface EventProps {
  action: string;
  category: string;
  label?: string;
  value?: number;
}

interface AnalyticsEvent {
  clickOnLogo: () => void;
  uploadInterior: () => void;
  openCompositionsExplorer: () => void;
  closeCompositionsExplorer: () => void;
  selectComposition: (compositionId: string) => void;
  removeComposition: () => void;
  openFramesExplorer: () => void;
  closeFramesExplorer: () => void;
  selectFrame: (frameColor: string) => void;
  openPostersExplorer: () => void;
  closePostersExplorer: () => void;
  selectCategory: (categoryId: string) => void;
  selectGallery: (galleryId: string) => void;
  selectPoster: (posterId: string) => void;
  updateInterior: () => void;
  saveProject: () => void;
  editProject: () => void;
  viewProduct: (productUrl: string) => void;
}

interface AnalyticsApi {
  pageview: (url: string) => void;
  event: AnalyticsEvent;
}

const AnalyticsContext = createContext<AnalyticsApi | null>(null);

interface AnalyticsProviderProps {
  children: React.ReactNode;
  trackingId?: string;
}

const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children, trackingId }) => {
  if (trackingId) {
    ReactGA.initialize(trackingId);
  }

  const pageview = useCallback(
    (url) => {
      if (!trackingId) return;

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`ga page view: ${url}`);

        return;
      }

      ReactGA.pageview(url);
    },
    [trackingId],
  );

  const event = useCallback(
    ({ action, category, label, value }: EventProps) => {
      if (!trackingId) return;

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`ga event: category: ${category}, action: ${action}, label: ${label}, value: ${value}`);

        return;
      }

      ReactGA.event({ category, action, label, value });
    },
    [trackingId],
  );

  const events = useMemo(() => {
    const category = 'interyApp';

    const result: AnalyticsEvent = {
      clickOnLogo: () => event({ action: 'clickOnLogo', category }),
      uploadInterior: () => event({ action: 'uploadInterior', category }),
      openCompositionsExplorer: () => event({ action: 'openCompositionsExplorer', category }),
      closeCompositionsExplorer: () => event({ action: 'closeCompositionsExplorer', category }),
      selectComposition: (compositionId) => event({ action: 'selectComposition', category, label: compositionId }),
      removeComposition: () => event({ action: 'removeComposition', category }),
      openFramesExplorer: () => event({ action: 'openFramesExplorer', category }),
      closeFramesExplorer: () => event({ action: 'closeFramesExplorer', category }),
      selectFrame: (frameColor) => event({ action: 'selectFrame', category, label: frameColor }),
      openPostersExplorer: () => event({ action: 'openPostersExplorer', category }),
      closePostersExplorer: () => event({ action: 'closePostersExplorer', category }),
      selectCategory: (categoryId) => event({ action: 'selectCategory', category, label: categoryId }),
      selectGallery: (galleryId) => event({ action: 'selectGallery', category, label: galleryId }),
      selectPoster: (posterId) => event({ action: 'selectPoster', category, label: posterId }),
      updateInterior: () => event({ action: 'updateInterior', category }),
      saveProject: () => event({ action: 'saveProject', category }),
      editProject: () => event({ action: 'editProject', category }),
      viewProduct: (productUrl: string) => event({ action: 'viewProduct', category, label: productUrl }),
    };

    return result;
  }, [event]);

  const analyticsApi = useMemo(
    () => ({
      pageview,
      event: events,
    }),
    [pageview, events],
  );

  return <AnalyticsContext.Provider value={analyticsApi}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = (): AnalyticsApi => {
  const ctx = useContext(AnalyticsContext);

  if (ctx === undefined || ctx === null) {
    throw new Error(
      'Error caught while consuming AnalyticsContext context. Make sure you wrap the Component inside the "AnalyticsProvider".',
    );
  }

  return ctx;
};

export default AnalyticsProvider;
