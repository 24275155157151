import React, { createContext, useContext } from 'react';

const TenantIdContext = createContext<string | null>(null);

interface GridPimProviderProps {
  children: React.ReactNode;
  tenantId: string;
}

const TenantIdProvider: React.FC<GridPimProviderProps> = ({ children, tenantId }) => {
  return <TenantIdContext.Provider value={tenantId}>{children}</TenantIdContext.Provider>;
};

export const useTenantId = (): string => {
  const ctx = useContext(TenantIdContext);

  if (ctx === undefined || ctx === null) {
    throw new Error(
      'Error caught while consuming TenantIdContext context. Make sure you wrap the Component inside the "TenantIdProvider".',
    );
  }

  return ctx;
};

export default TenantIdProvider;
