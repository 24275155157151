import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  selector: string;
  children: React.ReactNode;
}

const Portal: React.FC<PortalProps> = ({
  children,
  selector,
}): React.ReactPortal | null => {
  const ref = useRef<HTMLDivElement>(document.querySelector(selector));
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, [selector]);

  const node = ref.current;

  if (!node) return null;

  return mounted ? createPortal(children, node) : null;
};

export default Portal;
