import { useEffect, useState } from 'react';
import panzoom, { PanZoom } from 'panzoom';
import { InteriorTransform } from '../../types';

interface UsePanzoomProps {
  node?: HTMLDivElement | null;
  onTransform?: (data: InteriorTransform) => void;
}

const usePanZoom = ({ node, onTransform }: UsePanzoomProps) => {
  const [instance, setInstance] = useState<PanZoom | undefined>(undefined);

  useEffect(() => {
    if (!node) return;

    const newInstance = panzoom(node, {
      smoothScroll: false,
      bounds: true,
      zoomDoubleClickSpeed: 1,
    });

    newInstance.on('transform', (event: PanZoom) => {
      const data = event.getTransform();

      onTransform?.(data);
    });

    setInstance(newInstance);

    return () => {
      newInstance.dispose();
    };
  }, [onTransform, node]);

  return instance;
};

export default usePanZoom;
