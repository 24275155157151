import React, { useState } from 'react';
import { Field, FieldProps as FormikFieldProps } from 'formik';

export interface FieldProps extends FormikFieldProps {
  name: string;
  value: any;
  placeholder: string;
  error: boolean;
  errorMsg: string;
  isValid: boolean;
  onFocus: any;
  onBlur: any;
  onChange: any;
  type?: string;
  isFocused: boolean;
}

interface ErrorFnProps {
  submitFailed: boolean;
  isActive: boolean;
  isTouched: boolean;
  isError: boolean;
  value: any;
  initialValue: any;
}

const errorFn = ({ submitFailed, isActive, isTouched, isError, value, initialValue }: ErrorFnProps): boolean => {
  if (isActive) return false;

  if (isError && submitFailed) return true;

  return isTouched && isError && value !== initialValue;
};

interface ErrorMsgFnProps {
  submitFailed: boolean;
  isActive: boolean;
  isError: boolean;
  errorMessage: any;
}

const errorMsgFn = ({ submitFailed, isActive, isError, errorMessage }: ErrorMsgFnProps): string => {
  if (isActive) return '';

  if (isError && submitFailed) return errorMessage;

  return !isActive ? errorMessage : '';
};

interface ValidFnProps {
  isActive: boolean;
  isTouched: boolean;
  isError: boolean;
}

const validFn = ({ isActive, isTouched, isError }: ValidFnProps): boolean => !isActive && isTouched && !isError;

interface ComposedFieldProps {
  placeholder?: string;
  children: (props: FieldProps) => void;
  name: string;
  type?: string;
  validate?: (value: any) => string | void;
}

const defaultValidate = () => undefined;

const ComposedField: React.FC<ComposedFieldProps> = ({
  children,
  name,
  type,
  validate = defaultValidate,
  placeholder = '',
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  return (
    <Field name={name} validate={validate}>
      {({ field, form, meta }: FormikFieldProps) =>
        children({
          field,
          form,
          meta,
          ...props,
          name: field.name,
          value: field.value,
          type,
          placeholder,
          isFocused: focused,
          onChange: field.onChange,
          onFocus,
          onBlur: (event: any) => {
            field.onBlur(event);

            onBlur();
          },
          error: errorFn({
            submitFailed: !form.isValid && form.submitCount > 0,
            isActive: focused,
            isTouched: meta.touched,
            isError: !!meta.error,
            value: field.value,
            initialValue: form.initialValues[name],
          }),
          errorMsg: errorMsgFn({
            submitFailed: form.isValid && form.submitCount > 0,
            isActive: focused,
            isError: !!meta.error,
            errorMessage: form.errors[name],
          }),
          isValid: validFn({
            isActive: focused,
            isTouched: meta.touched,
            isError: !!meta.error,
          }),
        })
      }
    </Field>
  );
};

export default ComposedField;
