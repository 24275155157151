import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../translations/types';

const Container = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.scorpion};
  overflow: auto;
  z-index: 999;
`;

const Table = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`;

const Row = styled.div`
  display: table-row;
`;

const Cell = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px;
`;

const Holder = styled.div<{ secondary: boolean }>`
  position: relative;
  padding: 50px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${({ secondary }) =>
    secondary &&
    `
    padding: 0;
  `};

  @media (${({ theme }) => theme.breakpoints.S}) {
    width: 100%;
    padding: 40px 20px;
  }
`;

const CloseButton = styled.button<{ light: boolean }>`
  position: absolute;
  display: block;
  width: 38px;
  height: 38px;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.mineShaft};
  transition: color ${({ theme }) => theme.transition};

  @media ${({ theme }) => theme.hover} {
    color: ${({ theme }) => theme.colors.doveGray};
  }

  ${({ light, theme }) =>
    light &&
    `
    color: ${theme.colors.white};
  `};

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 18px;
    height: 2px;
    top: 50%;
    left: 50%;
    margin-left: -9px;
    margin-top: -1px;
    background-color: currentColor;
    transition: background-color 0.2s ease;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

interface ModalProps {
  children: React.ReactNode;
  onClose: (props: any) => void;
  secondary?: boolean;
  light?: boolean;
  testId?: string;
  onMount?: () => void;
  onUnMount?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  secondary = false,
  light = false,
  testId = 'modal',
  onMount,
  onUnMount,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (onMount) {
      onMount();
    }

    return () => {
      if (onUnMount) {
        onUnMount();
      }
    };
  }, [onMount, onUnMount]);

  return (
    <Container data-testid={testId}>
      <Table>
        <Row>
          <Cell>
            <Content>
              <Holder secondary={secondary}>
                {children}

                <CloseButton
                  type="button"
                  onClick={onClose}
                  aria-label={t(TranslationKey.close)}
                  data-testid={`${testId}-closeButton`}
                  light={light}
                />
              </Holder>
            </Content>
          </Cell>
        </Row>
      </Table>
    </Container>
  );
};

export default Modal;
