export enum TranslationKey {
  compositionFilter1 = 'compositionFilter1',
  compositionFilter2 = 'compositionFilter2',
  compositionFilter3 = 'compositionFilter3',
  compositionFilter4 = 'compositionFilter4',
  compositionFilter5 = 'compositionFilter5',
  compositionFilter6 = 'compositionFilter6',
  compositionFilter7 = 'compositionFilter7',
  compositionFilter8 = 'compositionFilter8',
  compositionFilterMore = 'compositionFilterMore',
  settingsFetchError = 'settingsFetchError',
  interiorFetchError = 'interiorFetchError',
  preselectedGalleryFetchError = 'preselectedGalleryFetchError',
  goBack = 'goBack',
  save = 'save',
  anyDifficulties = 'anyDifficulties',
  uploadInterior = 'uploadInterior',
  uploadOrPhoto = 'uploadOrPhoto',
  optimalDistance = 'optimalDistance',
  interiorDistance = 'interiorDistance',
  posters = 'posters',
  frames = 'frames',
  interior = 'interior',
  changePhoto = 'changePhoto',
  next = 'next',
  continue = 'continue',
  framesInstructions = 'framesInstructions',
  close = 'close',
  compositionError = 'compositionError',
  deviceInfo = 'deviceInfo',
  gotIt = 'gotIt',
  back = 'back',
  edit = 'edit',
  frameColor = 'frameColor',
  errorOccurred = 'errorOccurred',
  openWindowAgain = 'openWindowAgain',
  forward = 'forward',
  categories = 'categories',
  interiorReady = 'interiorReady',
  selectedProductsInfo = 'selectedProductsInfo',
  selectedProductsTitle = 'selectedProductsTitle',
  more = 'more',
  summarySurveyTitle = 'summarySurveyTitle',
  takeSurvey = 'takeSurvey',
  remove = 'remove',
  changeSize = 'changeSize',
  modalSurveyTitle = 'modalSurveyTitle',
  surveyTime = 'surveyTime',
  interiorHint = 'interiorHint',
  introTitle = 'introTitle',
  introSubtitle = 'introSubtitle',
  introCta = 'introCta',
  measurementTitle = 'measurementTitle',
  measurementSubmit = 'measurementSubmit',
  measurementPlaceholder = 'measurementPlaceholder',
}

export const test = 'test';
