import React, { useCallback, useEffect, useRef } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CloseButton from '../CloseButton';
import { TranslationKey } from '../../translations/types';

const CloseButtonContainer = styled.div`
  position: absolute;
  top: -22px;
  right: 2px;
`;

const DataExplorerHeaderContainer = styled.div<{ isHidden: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.iron};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 -3px 11px 0 ${({ theme }) => rgba(theme.colors.black, 0.16)};

  ${({ isHidden }) =>
    isHidden &&
    `
    display: none;
  `};
`;

interface DataExplorerHeaderProps {
  isHidden?: boolean;
  onBack: () => void;
  children: React.ReactNode;
}

export const DataExplorerHeader: React.FC<DataExplorerHeaderProps> = ({ isHidden = false, children, onBack }) => {
  const { t } = useTranslation();

  return (
    <DataExplorerHeaderContainer isHidden={isHidden}>
      {children}

      <CloseButtonContainer>
        <CloseButton onClick={onBack} label={t(TranslationKey.back)} />
      </CloseButtonContainer>
    </DataExplorerHeaderContainer>
  );
};

interface DataExplorerContentProps {
  children: React.ReactNode;
}

const DataExplorerContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 107px;
  background: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

export const DataExplorerContent: React.FC<DataExplorerContentProps> = ({ children }) => {
  return <DataExplorerContentContainer>{children}</DataExplorerContentContainer>;
};

const DataExplorerScrollableContainerWrapper = styled.div<{ isHidden: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  ${({ isHidden }) =>
    isHidden &&
    `
    display: none;
  `};
`;

interface DataExplorerScrollableContainerProps {
  isHidden?: boolean;
  children: React.ReactNode;
  scrollLeft?: number;
  onScroll?: (scrollLeft: number) => void;
}

export const DataExplorerScrollableContainer: React.FC<DataExplorerScrollableContainerProps> = ({
  isHidden = false,
  children,
  scrollLeft = 0,
  onScroll,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onContainerScroll = useCallback(() => {
    const node = containerRef.current;

    if (!node) return;

    onScroll?.(node.scrollLeft);
  }, [containerRef, onScroll]);

  useEffect(() => {
    const node = containerRef.current;

    if (!node) return;

    setTimeout(() => {
      node.scrollLeft = scrollLeft;
    }, 0);
  }, [scrollLeft]);

  return (
    <DataExplorerScrollableContainerWrapper isHidden={isHidden} ref={containerRef} onScroll={onContainerScroll}>
      {children}
    </DataExplorerScrollableContainerWrapper>
  );
};

const DataExplorerContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

interface DataExplorerProps {
  children: React.ReactNode;
  onMount?: () => void;
  onUnMount?: () => void;
}

const DataExplorer: React.FC<DataExplorerProps> = ({ children, onMount, onUnMount }) => {
  useEffect(() => {
    if (onMount) {
      onMount();
    }

    return () => {
      if (onUnMount) {
        onUnMount();
      }
    };
  }, [onMount, onUnMount]);

  return <DataExplorerContainer>{children}</DataExplorerContainer>;
};

export default DataExplorer;
