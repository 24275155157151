import { useEffect } from 'react';
import { useInterior as useInitialInterior } from '../InteriorProvider';

interface UseAutoPreselectedGalleryProps {
  isSceneReady: boolean;
  onAfterConfirmInterior: () => void;
}

const useAutoPreselectedGallery = ({ isSceneReady, onAfterConfirmInterior }: UseAutoPreselectedGalleryProps) => {
  const initialInterior = useInitialInterior();

  useEffect(() => {
    if (!isSceneReady) return;

    if (!initialInterior.preview) return;

    onAfterConfirmInterior();
  }, [isSceneReady, initialInterior, onAfterConfirmInterior]);
};

export default useAutoPreselectedGallery;
