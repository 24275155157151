import React, { createContext, useCallback, useContext, useState, useMemo } from 'react';
import { z } from 'zod';
import { useMutation, useQuery } from 'react-query';
import round from 'lodash/round';
import * as browserDbService from '../../services/browserDBService';

export const measurementControlSize = 60;
export const horizontalOffset = 100;

export const PointSchema = z.object({
  x: z.number(),
  y: z.number(),
});

export const MeasurementsSchema = z.object({
  distanceInCm: z.number(),
  point1: PointSchema,
  point2: PointSchema,
});

export type Measurements = z.infer<typeof MeasurementsSchema>;

export type Point = z.infer<typeof PointSchema>;

interface MeasurementsContextValue {
  measurements: Measurements;
  onChangeMeasurements: (newMeasurements: Measurements) => void;
}

const MeasurementsContext = createContext<MeasurementsContextValue | null>(null);

const usePersistMeasurements = () => {
  return useMutation((measurements: Measurements) => browserDbService.measurements.set(JSON.stringify(measurements)));
};

interface MeasurementsProviderProps {
  measurements: Measurements;
  children: React.ReactNode;
}

const MeasurementsProvider: React.FC<MeasurementsProviderProps> = ({ measurements: initialMeasurements, children }) => {
  const [measurements, setMeasurements] = useState(initialMeasurements);

  const { mutate: mutatePersistMeasurements } = usePersistMeasurements();

  const onChangeMeasurements = useCallback(
    (newMeasurements: Measurements) => {
      setMeasurements(newMeasurements);

      mutatePersistMeasurements({
        distanceInCm: newMeasurements.distanceInCm,
        point1: newMeasurements.point1,
        point2: newMeasurements.point2,
      });
    },
    [mutatePersistMeasurements],
  );

  const value = useMemo(
    () => ({
      measurements,
      onChangeMeasurements,
    }),
    [measurements, onChangeMeasurements],
  );

  return <MeasurementsContext.Provider value={value}>{children}</MeasurementsContext.Provider>;
};

export const useMeasurements = (): MeasurementsContextValue => {
  const ctx = useContext(MeasurementsContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming MeasurementsContext. Make sure you wrap the Component inside the "MeasurementsProvider".',
    );
  }

  return ctx;
};

export const usePersistedMeasurements = () => {
  const fetchMeasurements = async () => {
    const response = await browserDbService.measurements.get();

    if (typeof response !== 'string') {
      throw new Error('Invalid measurements data');
    }

    const measurements = MeasurementsSchema.parse(JSON.parse(response));

    return measurements;
  };

  return useQuery('measurements', fetchMeasurements, { refetchOnWindowFocus: false, retry: false });
};

export const getDistanceInPx = ({ x: x1, y: y1 }: Point, { x: x2, y: y2 }: Point) => {
  const result = Math.round(Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2));

  return result;
};

export const getPxToCmRatio = ({ distanceInPx, distanceInCm }: { distanceInPx: number; distanceInCm: number }) => {
  if (distanceInCm === 0) {
    return 1;
  }

  const result = round(distanceInPx / distanceInCm, 1);

  return result;
};

export default MeasurementsProvider;
