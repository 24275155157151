import { useQuery, UseQueryResult } from 'react-query';
import { Category } from '../types';
import { useTenantId } from '../components/TenantIdProvider';
import * as azureService from '../services/azureService';

const useCategories = (parentId: string): UseQueryResult<Category[], unknown> => {
  const tenantId = useTenantId();

  return useQuery<Category[]>(['categories', 'parentId', parentId], () =>
    azureService.getCategories({ tenantId, parentId }),
  );
};

export default useCategories;
