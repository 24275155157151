import React, { createContext, useContext } from 'react';
import { Interior } from '../../types';

const InteriorContext = createContext<Interior | null>(null);

interface InteriorProviderProps {
  interior: Interior;
  children: React.ReactNode;
}

const InteriorProvider: React.FC<InteriorProviderProps> = ({ children, interior }) => {
  return <InteriorContext.Provider value={interior}>{children}</InteriorContext.Provider>;
};

export const useInterior = (): Interior => {
  const ctx = useContext(InteriorContext);

  if (ctx === undefined || ctx === null) {
    throw new Error(
      'Error caught while consuming InteriorContext context. Make sure you wrap the Component inside the "InteriorProvider".',
    );
  }

  return ctx;
};

export default InteriorProvider;
