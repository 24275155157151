import styled from 'styled-components';
import { rgba } from 'polished';
import React from 'react';

const Container = styled.button<{ size: Size }>`
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background-color: transparent;

  svg {
    position: relative;
    display: block;
    width: 10px;
    height: 10px;
    z-index: 1;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &:before {
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.athensGray};
    box-shadow: 0 0 2px ${({ theme }) => rgba(theme.colors.mineShaft, 0.6)};

    ${({ size }) =>
      size === 'small' &&
      `
      width: 20px;
      height: 20px;
    `}
  }
`;

type Size = 'small' | 'default';

interface RemoveButtonProps {
  onClick: () => void;
  label?: string;
  size?: Size;
}

const CloseButton: React.FC<RemoveButtonProps> = ({ onClick, label = 'Закрыть', size = 'default' }) => {
  return (
    <Container type="button" aria-label={label} onTouchEnd={onClick} size={size}>
      <svg viewBox="0 0 329.26933 329">
        <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
      </svg>
    </Container>
  );
};

export default CloseButton;
