import qs from 'query-string';
import { useMemo } from 'react';

function useQuery<T = any>() {
  const query = useMemo(() => {
    return qs.parse(window.location.search) as unknown as T;
  }, []);

  return query;
}

export default useQuery;
