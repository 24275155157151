import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../translations/types';

const Container = styled.button`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.mineShaft};
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 9px;
  margin-right: 7px;
  top: -2px;

  svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  svg path {
    fill: currentColor;
  }
`;

const Text = styled.div`
  color: currentColor;
`;

interface BackButtonProps {
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Container type="button" onClick={onClick}>
      <IconContainer>
        <svg version="1.1" x="0px" y="0px" width="15.5px" height="9px" viewBox="0.5 0.5 15.5 9">
          <path
            d="M6.063,0.56c0.208-0.112,0.474-0.063,0.627,0.128l0,0l0.05,0.075
              c0.113,0.209,0.063,0.475-0.128,0.628l0,0L2.725,4.5H15.5C15.776,4.5,16,4.724,16,5s-0.224,0.5-0.5,0.5H2.727l3.886,3.109
              l0.065,0.062c0.156,0.179,0.167,0.449,0.013,0.641c-0.173,0.215-0.487,0.25-0.703,0.078l0,0L0.5,5l5.488-4.391L6.063,0.56z"
          />
        </svg>
      </IconContainer>

      <Text>{t(TranslationKey.goBack)}</Text>
    </Container>
  );
};

export default BackButton;
