import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

const useFullHeight = (headerHeight: number) => {
  const [height, setHeight] = useState(0);

  const updateHeight = throttle(() => setHeight(window.innerHeight - headerHeight), 100);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);

    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  });

  return height;
};

export default useFullHeight;
