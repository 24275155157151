import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../translations/types';

const ErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  font-size: 16px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.persimmon};
  text-align: center;
  transform: translate(-50%, -50%);
`;

const Error: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      {t(TranslationKey.errorOccurred)}
      <br />
      {t(TranslationKey.openWindowAgain)}
    </ErrorContainer>
  );
};

export default Error;
