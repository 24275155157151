import queryString from 'query-string';
import { NewComposition } from '../../types';

interface BuildUtmLinkProps {
  url: string;
  campaign: string;
  source: string;
  medium: string;
}

const buildUtmLink = ({ url, campaign, source, medium }: BuildUtmLinkProps): string => {
  const newUrl = `${url}&utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;

  return newUrl;
};

const buildExternalCheckoutLink = (baseUrl: string, compositions: NewComposition[]): string => {
  const posters = compositions
    .map((composition) => ({
      id: composition.poster.itemId,
      size: { width: composition.size.width, height: composition.size.height },
    }))
    .map((poster) => JSON.stringify(poster));

  const params = { posters };

  const url = `${baseUrl}?${queryString.stringify(params)}`;

  const result = buildUtmLink({
    url,
    campaign: 'intery_app',
    source: 'intery_app',
    medium: 'intery_app_external_checkout',
  });

  return result;
};

export default buildExternalCheckoutLink;
