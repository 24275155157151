import React, { useCallback } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Interior, InteriorTransform } from '../../types';
import Background, { InteriorEditingState } from './Background';

const Container = styled.div<{
  width: number;
  height: number;
  isDisabled: boolean;
  themeType: Theme;
}>`
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  outline: none;

  ${({ themeType, theme }) =>
    themeType === 'light' &&
    `
    background-color: ${theme.colors.alabaster};
  `};

  ${({ themeType, theme }) =>
    themeType === 'dark' &&
    `
    background-color: ${rgba(theme.colors.shuttleGray, 0.3)};
  `};

  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
  `};
`;

type Theme = 'light' | 'dark';

interface SceneProps {
  width: number;
  height: number;
  onExternalClick?: () => void;
  children: React.ReactNode;
  interior: Interior;
  interiorEditingState?: InteriorEditingState;
  onTransformInterior?: (data: InteriorTransform) => void;
  isDisabled?: boolean;
  sceneRef?: React.RefObject<HTMLDivElement>;
  theme?: Theme;
}

const Scene: React.FC<SceneProps> = ({
  width,
  height,
  onExternalClick,
  children,
  interior,
  interiorEditingState = 'disabled',
  onTransformInterior,
  isDisabled = false,
  sceneRef,
  theme = 'light',
}) => {
  const onReset = useCallback(
    (event: React.TouchEvent) => {
      const { target, currentTarget } = event;

      if (target !== currentTarget) return;

      if (!onExternalClick) return;

      onExternalClick();
    },
    [onExternalClick],
  );

  return (
    <Container
      width={width}
      height={height}
      isDisabled={isDisabled}
      themeType={theme}
      onTouchEnd={onReset}
      ref={sceneRef}
    >
      <Background
        key={interior.preview}
        width={width}
        height={height}
        interior={interior}
        initialX={interior.initialTransform.x}
        initialY={interior.initialTransform.y}
        initialZoom={interior.initialTransform.scale}
        interiorEditingState={interiorEditingState}
        onTransformInterior={onTransformInterior}
      />

      {children}
    </Container>
  );
};

export default Scene;
