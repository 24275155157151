import Axios from 'axios';
import { Category, Composition, CompositionsFilter, Gallery, Tenant } from '../types';

const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:7071/api'
    : 'https://homelly-ru-admin-service.azurewebsites.net/api';

const axios = Axios.create({
  baseURL,
});

interface GeneralProps {
  tenantId: string;
}

interface GetCompositionsProps extends GeneralProps {
  postersCount?: number;
  include: string[];
}

export const getCompositions = async ({
  tenantId,
  postersCount,
  include,
}: GetCompositionsProps): Promise<Composition[]> => {
  const response = await axios.get(
    `/gallery-builder/tenants/${tenantId}/compositions?postersCount=${postersCount}&include=${JSON.stringify(include)}`,
  );

  return response.data;
};

interface GetCompositionsFiltersProps extends GeneralProps {
  include: string[];
}

export const getCompositionsFilters = async ({
  tenantId,
  include,
}: GetCompositionsFiltersProps): Promise<CompositionsFilter[]> => {
  const response = await axios.get(
    `/gallery-builder/tenants/${tenantId}/compositions/filters?include=${JSON.stringify(include)}`,
  );

  return response.data;
};

interface GetCategoriesProps extends GeneralProps {
  parentId: string;
}

export const getCategories = async ({ tenantId, parentId }: GetCategoriesProps): Promise<Category[]> => {
  const response = await axios.get(`gallery-builder/tenants/${tenantId}/categories?parentId=${parentId}`);

  return response.data;
};

interface GetGalleriesProps extends GeneralProps {
  categoryId: string;
  page?: number;
}

export interface GetGalleriesResponse {
  galleries: Gallery[];
  total: number;
}

export const getGalleries = async ({
  tenantId,
  categoryId,
  page = 1,
}: GetGalleriesProps): Promise<GetGalleriesResponse> => {
  const response = await axios.get(`gallery-builder/tenants/${tenantId}/galleries/category/${categoryId}/page/${page}`);

  return response.data;
};

interface GetGalleryProps extends GeneralProps {
  galleryId: string;
}

export const getGallery = async ({ tenantId, galleryId }: GetGalleryProps): Promise<Gallery> => {
  const response = await axios.get<Gallery>(`gallery-builder/tenants/${tenantId}/galleries/${galleryId}`);

  return response.data;
};

export const getTenant = async ({ tenantId }: GeneralProps): Promise<Tenant> => {
  const response = await axios.get(`gallery-builder/tenants/${tenantId}`);

  return response.data;
};
