import React, { createContext, useContext } from 'react';
import { Tenant } from '../../types';

const TenantContext = createContext<Tenant | null>(null);

interface TenantProviderProps {
  children: React.ReactNode;
  tenant: Tenant;
}

const TenantProvider: React.FC<TenantProviderProps> = ({ children, tenant }): JSX.Element => {
  return <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>;
};

export const useTenant = (): Tenant => {
  const ctx = useContext(TenantContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming TenantContext context. Make sure you wrap the Component inside the "TenantProvider" component.',
    );
  }

  return ctx;
};

export default TenantProvider;
