import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { rgba } from 'polished';
import isMobile from 'ismobilejs';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import useFullHeight from '../../hooks/useFullHeight';
import FramesDataExplorer from '../FramesDataExplorer';
import PostersDataExplorer from '../PostersDataExplorer';
import SizesExplorer from '../SizesExplorer';
import SceneComposition from '../SceneComposition';
import ProjectSummary from '../ProjectSummary';
import SceneComponent from '../Scene';
import {
  LinkedPoster,
  GroupFrame,
  CompositionTransform,
  NewComposition,
  buildNewComposition,
  PosterSize,
  Gallery,
  buildLinkedPoster,
  buildBuiltInCheckout,
  CheckoutType,
} from '../../types';
import CompositionView, { getCompositionPxSize } from '../CompositionView';
import ModalComponent from '../Modal';
import Logo from '../Logo';
import useSceneSize from '../../hooks/useSceneSize';
import introImage from '../../assets/images/vis-gallery-builder-intro@2x.jpg';
import { useTenant } from '../TenantProvider';
import { useAnalytics } from '../AnalyticsProvider';
import Portal from '../Portal';
import Button from '../Button';
import SurveyModal, { useSurveyModal } from '../SurveyModal';
import getCompositionOffsetSelector from './getCompositionOffsetSelector';
import getPosterSize from './getPosterSize';
import InteriorControls from './InteriorControls';
import useInterior from './useInterior';
import buildExternalCheckoutLink from './buildExternalCheckoutLink';
import { TranslationKey } from '../../translations/types';
import InteriorHint from './InteriorHint';
import useLimitedPreselectedGallery from './useLimitedPreselectedGallery';
import useAutoPreselectedGallery from './useAutoPreselectedGallery';
import useBuilderState from './useBuilderState';
import MeasurementControls from './MeasurementControls';
import BuilderControls from './BuilderControls';
import { getDistanceInPx, getPxToCmRatio, useMeasurements } from '../MeasurementsProvider';

const getCompositionOffset = getCompositionOffsetSelector();

const getPreselectedCompositionOffset = getCompositionOffsetSelector();

const HEADER_HEIGHT = 46;
const CONTROLS_HEIGHT = 66;

const Container = styled.section<{ height: number }>`
  position: fixed;
  width: 100%;
  max-width: 375px;
  min-width: 360px;
  height: ${({ height }) => `${height}px`};
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  touch-action: none;
  overflow: hidden;
`;

const Header = styled.div<{ isShifted: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  padding: 0 10px;
  transition: transform 0.15s ease;

  ${({ isShifted }) =>
    isShifted &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transform: translateY(${HEADER_HEIGHT * -1}px);
  `};
`;

const Scene = styled.div<{ width: number; height: number; isShifted: boolean }>`
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  top: ${HEADER_HEIGHT}px;
  left: 50%;
  margin-left: -177.5px;
  border-radius: 14px;
  overflow: hidden;
  transition: transform 0.15s ease;

  ${({ isShifted }) =>
    isShifted &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transform: translateY(${HEADER_HEIGHT * -1}px);
  `};
`;

const FileField = styled.input`
  display: none;
`;

const Intro = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const IntroWrapper = styled.div`
  width: 100%;
`;

const IntroHero = styled.div`
  width: 214px;
  height: 228px;
  margin: 0 auto 18px;

  img {
    display: block;
    max-width: 100%;
  }
`;

const IntroTitle = styled.div`
  margin: 0 auto 5px;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.23;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;

const IntroSubtitle = styled.div`
  margin: 0 auto 25px;
  max-width: 225px;
  font-size: 18px;
  line-height: 1.28;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  opacity: 0.8;
`;

const IntroControl = styled.div`
  display: flex;
  justify-content: center;
`;

const DataExplorer = styled.div<{ isHidden?: boolean }>`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  ${({ isHidden }) =>
    isHidden &&
    `
    visibility: hidden;
    pointer-events: none;
  `}
`;

const Modal = styled.div``;

const ModalControl = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 46px;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
  appearance: none;
  border: none;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.black};
`;

const ModalTitle = styled.div`
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;

const ModalControls = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const LogoContainer = styled.div`
  padding-left: 10px;
`;

const SaveButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const RoundButton = styled.button<{ hasShadow?: boolean }>`
  position: relative;
  display: flex;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.mineShaft};
  appearance: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;

  ${({ hasShadow, theme }) =>
    hasShadow &&
    `
    box-shadow: 0 2px 6px 0 ${rgba(theme.colors.black, 0.16)};
  `}
`;

const ExplorerContainer = styled.div<{ isVisible: boolean }>`
  display: block;

  ${({ isVisible }) =>
    isVisible === false &&
    `
    display: none;
  `};
`;

interface GalleryBuilderProps {}

const GalleryBuilder: React.FC<GalleryBuilderProps> = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const builderHeight = useFullHeight(0);
  const sceneSize = useSceneSize(HEADER_HEIGHT + CONTROLS_HEIGHT);
  const analyticsService = useAnalytics();
  const getPreselectedGallery = useLimitedPreselectedGallery();
  const { measurements } = useMeasurements();

  const {
    interior,
    fileFieldRef,
    onTransformInterior,
    onUploadInterior,
    onUpdateInterior,
    onUploadFile,
    onConfirmInterior,
  } = useInterior();

  const {
    isIdle,
    isEditingInterior,
    isMeasuring,
    isFraming,
    isSelectingPoster,
    isTerminating,
    sendUploadInterior,
    sendEditInterior,
    sendCloseFrames,
    sendClosePosters,
    sendAbort,
    sendSaveInterior,
    sendSaveMeasurement,
    sendViewFrames,
    sendViewPosters,
    sendSaveProject,
  } = useBuilderState({ isAnyInteriorExists: interior.source.size > 0 });

  const initialDistanceInPx = getDistanceInPx(measurements.point1, measurements.point2);

  const initialPxToCmRatio = getPxToCmRatio({
    distanceInPx: initialDistanceInPx,
    distanceInCm: measurements.distanceInCm,
  });

  const [pxToCmRatio, setPxToCmRatio] = useState(initialPxToCmRatio);

  const onChangePxToCmRatio = useCallback((value: number) => setPxToCmRatio(value), []);

  const { isSurveyModalVisible, onCloseSurveyModal } = useSurveyModal();

  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const showInfoModal = useCallback(() => setIsInfoModalVisible(true), []);
  const hideInfoModal = useCallback(() => setIsInfoModalVisible(false), []);

  const [isCompositionErrorModalVisible, setIsCompositionErrorModalVisible] = useState(false);
  const showCompositionErrorModal = useCallback(() => setIsCompositionErrorModalVisible(true), []);
  const hideCompositionErrorModal = useCallback(() => setIsCompositionErrorModalVisible(false), []);

  const [isDeviceModalVisible, setIsDeviceModalVisible] = useState(false);
  const showDeviceModal = useCallback(() => setIsDeviceModalVisible(true), []);
  const hideDeviceModal = useCallback(() => setIsDeviceModalVisible(false), []);

  const [isSizesExplorerVisible, setIsSizesExplorerVisible] = useState(false);
  const showSizesExplorer = useCallback(() => setIsSizesExplorerVisible(true), []);
  const hideSizesExplorer = useCallback(() => setIsSizesExplorerVisible(false), []);

  const [activeCompositionId, setActiveCompositionId] = useState('');
  const resetActiveCompositionId = useCallback(() => setActiveCompositionId(''), []);
  const activateComposition = useCallback((compositionId: string) => {
    setActiveCompositionId(compositionId);
  }, []);

  const [compositions, setCompositions] = useState<NewComposition[]>([]);
  const onAddComposition = useCallback(
    (composition: NewComposition) => {
      setCompositions((prevState) => [...prevState, composition]);

      activateComposition(composition.id);
    },
    [activateComposition],
  );
  const onRemoveComposition = useCallback(
    (compositionId: string) => {
      setCompositions((prevState) => prevState.filter((composition) => composition.id !== compositionId));

      hideSizesExplorer();

      analyticsService.event.removeComposition();
    },
    [analyticsService, hideSizesExplorer],
  );
  const onTransformComposition = useCallback((compositionId: string, { x, y, scale }: CompositionTransform) => {
    setCompositions((prevCompositions) => {
      const nextCompositions = prevCompositions.map((composition) => {
        if (composition.id !== compositionId) return composition;

        const newComposition = buildNewComposition({ ...composition, x, y, scale });

        return newComposition;
      });

      return nextCompositions;
    });
  }, []);
  const onChangeCompositionSize = useCallback((compositionId: string, size: PosterSize) => {
    setCompositions((prevCompositions) => {
      const newCompositions = prevCompositions.map((composition) => {
        if (composition.id !== compositionId) return composition;

        const newComposition = buildNewComposition({ ...composition, size });

        return newComposition;
      });

      return newCompositions;
    });
  }, []);

  const activeComposition = useMemo(() => {
    const result = compositions.find((composition) => composition.id === activeCompositionId);

    return result;
  }, [activeCompositionId, compositions]);

  const onSelectPoster = useCallback(
    (poster: LinkedPoster, transformOptions: { x?: number; y?: number; scale?: number } = {}) => {
      const size = getPosterSize(poster);

      if (!size) {
        showCompositionErrorModal();

        return;
      }

      const compositionOffset = getCompositionOffset();

      const initialX = transformOptions.x ?? compositionOffset.x;
      const initialY = transformOptions.y ?? compositionOffset.y;
      const initialScale = transformOptions.scale ?? 1;

      const newComposition = buildNewComposition({
        id: uuidV4(),
        size,
        poster,
        initialX,
        initialY,
        initialScale,
      });

      onAddComposition(newComposition);
    },
    [onAddComposition, showCompositionErrorModal],
  );

  const onPreselectGallery = useCallback(
    (gallery: Gallery) => {
      const posters = gallery.items.map((poster) => buildLinkedPoster(poster, gallery.link));

      const sceneWidth = sceneSize.width;
      const sceneHeight = sceneSize.height;

      posters.forEach((poster) => {
        const size = getPosterSize(poster);

        if (!size) {
          showCompositionErrorModal();

          return;
        }

        const compositionWidth = getCompositionPxSize(size.width, pxToCmRatio);
        const compositionHeight = getCompositionPxSize(size.height, pxToCmRatio);

        const x = Math.ceil((sceneWidth - compositionWidth) / 2);
        const y = Math.ceil((sceneHeight - compositionHeight) / 2);

        const compositionOffset = getPreselectedCompositionOffset();

        const transformOptions = { x: x + compositionOffset.x, y: y + compositionOffset.y };

        onSelectPoster(poster, transformOptions);

        analyticsService.event.selectPoster(poster.id);
      });
    },
    [onSelectPoster, sceneSize, showCompositionErrorModal, analyticsService, pxToCmRatio],
  );

  const onSelectFrame = useCallback(
    (frame: GroupFrame) => {
      if (!activeComposition) {
        showInfoModal();

        return;
      }

      setCompositions((prevCompositions) => {
        const nextCompositions = prevCompositions.map((composition) => {
          if (composition.id !== activeComposition?.id) return composition;

          const newComposition = buildNewComposition({
            ...composition,
            frame,
          });

          return newComposition;
        });

        return nextCompositions;
      });
    },
    [activeComposition, showInfoModal],
  );

  const onAfterConfirmInterior = useCallback(() => {
    sendSaveMeasurement();

    const preselectedGallery = getPreselectedGallery();

    if (preselectedGallery) {
      onPreselectGallery(preselectedGallery);
    }
  }, [sendSaveMeasurement, onPreselectGallery, getPreselectedGallery]);

  const onSaveMeasurement = useCallback(
    (newPxToCmRatio: number) => {
      onChangePxToCmRatio(newPxToCmRatio);

      onConfirmInterior(onAfterConfirmInterior);
    },
    [onChangePxToCmRatio, onConfirmInterior, onAfterConfirmInterior],
  );

  useAutoPreselectedGallery({
    isSceneReady: sceneSize.width > 0 && sceneSize.height > 0,
    onAfterConfirmInterior,
  });

  const isShifted = isFraming || isSelectingPoster;

  const onExternalClick = useCallback(() => {
    resetActiveCompositionId();
    hideSizesExplorer();
  }, [resetActiveCompositionId, hideSizesExplorer]);

  const isAnyCompositionSelected = compositions.length > 0;

  useEffect(() => {
    const isMobileDevice = isMobile();

    if (isMobileDevice.phone) return;

    showDeviceModal();
  }, [showDeviceModal]);

  const onSave = useCallback(() => {
    const checkout = tenant.checkout ?? buildBuiltInCheckout();

    switch (checkout.type) {
      case CheckoutType.BuiltIn: {
        sendSaveProject(analyticsService.event.saveProject);

        break;
      }

      case CheckoutType.External: {
        const checkoutLink = buildExternalCheckoutLink(checkout.checkoutUrl, compositions);

        window.open(checkoutLink, '_blank');

        break;
      }

      default: {
        sendSaveProject(analyticsService.event.saveProject);
      }
    }
  }, [compositions, sendSaveProject, tenant, analyticsService.event.saveProject]);

  return (
    <>
      <Container height={builderHeight}>
        <Header isShifted={isShifted}>
          {!isEditingInterior && (
            <LogoContainer>
              <Logo imgUrl={tenant.logo} link={tenant.link} onClick={analyticsService.event.clickOnLogo} />
            </LogoContainer>
          )}

          {isAnyCompositionSelected && !isEditingInterior && !isMeasuring && (
            <RoundButton type="button" onClick={onSave}>
              {t(TranslationKey.save)}
            </RoundButton>
          )}

          {isEditingInterior && <InteriorHint />}
        </Header>

        <Scene width={sceneSize.width} height={sceneSize.height} isShifted={isShifted}>
          <SceneComponent
            width={sceneSize.width}
            height={sceneSize.height}
            onExternalClick={onExternalClick}
            interior={interior}
            interiorEditingState={isEditingInterior ? 'active' : 'disabled'}
            onTransformInterior={onTransformInterior}
          >
            {compositions.map((composition) => {
              const isActive = composition.id === activeComposition?.id && !isEditingInterior;

              const isHidden = isIdle || isEditingInterior || isMeasuring;

              return (
                <SceneComposition
                  key={composition.id}
                  id={composition.id}
                  isActive={isActive}
                  isHidden={isHidden}
                  initialX={composition.initialX}
                  initialY={composition.initialY}
                  initialZoom={composition.initialScale}
                  maxZoom={1}
                  minZoom={1}
                  onActivate={activateComposition}
                  onRemove={onRemoveComposition}
                  onTransform={onTransformComposition}
                  onEdit={showSizesExplorer}
                >
                  <CompositionView
                    id={composition.id}
                    width={composition.size.width}
                    height={composition.size.height}
                    posterUrl={composition.poster.thumbnail}
                    pxToCmRatio={pxToCmRatio}
                    frameColor={composition.frame}
                  />
                </SceneComposition>
              );
            })}

            {isAnyCompositionSelected && isShifted && (
              <Portal selector="#saveButton">
                <SaveButtonContainer>
                  <RoundButton type="button" onClick={onSave} hasShadow>
                    {t(TranslationKey.save)}
                  </RoundButton>
                </SaveButtonContainer>
              </Portal>
            )}
          </SceneComponent>

          {isIdle && (
            <Intro>
              <IntroWrapper>
                <IntroHero>
                  <img src={introImage} alt="" />
                </IntroHero>

                <IntroTitle>{t(TranslationKey.uploadInterior)}</IntroTitle>

                <IntroSubtitle>
                  {t(TranslationKey.optimalDistance)} - <strong>{t(TranslationKey.interiorDistance)}</strong>
                </IntroSubtitle>

                <IntroControl>
                  <Button type="button" onClick={onUploadInterior}>
                    {t(TranslationKey.uploadOrPhoto)}
                  </Button>
                </IntroControl>
              </IntroWrapper>
            </Intro>
          )}
        </Scene>

        <FileField
          type="file"
          accept="image/*"
          ref={fileFieldRef}
          onChange={(event) => onUploadFile(event, sendUploadInterior)}
        />

        {!isIdle && !isEditingInterior && !isMeasuring && (
          <BuilderControls
            isPostersControlDisabled={isIdle}
            onPostersControlClick={() => sendViewPosters(resetActiveCompositionId)}
            isFramesControlDisabled={isIdle || !isAnyCompositionSelected}
            onFramesControlClick={() => sendViewFrames(resetActiveCompositionId)}
            isInteriorControlDisabled={isIdle}
            onInteriorControlClick={() => sendEditInterior()}
            controlsHeight={CONTROLS_HEIGHT}
          />
        )}

        {isEditingInterior && <InteriorControls onEdit={onUpdateInterior} onSave={() => sendSaveInterior()} />}

        {isMeasuring && (
          <MeasurementControls
            onSave={onSaveMeasurement}
            onBack={() => sendEditInterior()}
            heightOffset={HEADER_HEIGHT}
            point1={measurements.point1}
            point2={measurements.point2}
            distanceInCm={measurements.distanceInCm}
          />
        )}

        <DataExplorer isHidden={isSizesExplorerVisible}>
          <ExplorerContainer isVisible={isFraming}>
            <FramesDataExplorer
              onSelectFrame={onSelectFrame}
              onClose={() => sendCloseFrames(resetActiveCompositionId)}
            />
          </ExplorerContainer>

          <ExplorerContainer isVisible={isSelectingPoster}>
            <PostersDataExplorer
              onPickPoster={onSelectPoster}
              onClose={() => sendClosePosters(resetActiveCompositionId)}
            />
          </ExplorerContainer>
        </DataExplorer>

        <DataExplorer>
          {isSizesExplorerVisible && activeComposition && (
            <SizesExplorer
              compositionId={activeComposition.id}
              sizes={activeComposition.poster.sizes}
              defaultSize={activeComposition.poster.defaultSize}
              currentSize={activeComposition.size}
              onChangeSize={onChangeCompositionSize}
              onClose={hideSizesExplorer}
            />
          )}
        </DataExplorer>

        {isInfoModalVisible && (
          <Modal>
            <ModalComponent onClose={hideInfoModal}>
              <ModalTitle>{t(TranslationKey.framesInstructions)}</ModalTitle>

              <ModalControls>
                <ModalControl type="button" onClick={hideInfoModal}>
                  {t(TranslationKey.continue)}
                </ModalControl>
              </ModalControls>
            </ModalComponent>
          </Modal>
        )}

        {isCompositionErrorModalVisible && (
          <Modal>
            <ModalComponent onClose={hideCompositionErrorModal}>
              <ModalTitle>{t(TranslationKey.compositionError)}</ModalTitle>

              <ModalControls>
                <ModalControl type="button" onClick={hideCompositionErrorModal}>
                  {t(TranslationKey.close)}
                </ModalControl>
              </ModalControls>
            </ModalComponent>
          </Modal>
        )}
      </Container>

      {isDeviceModalVisible && (
        <Modal>
          <ModalComponent onClose={hideDeviceModal}>
            <ModalTitle>{t(TranslationKey.deviceInfo)}</ModalTitle>

            <ModalControls>
              <ModalControl type="button" onClick={hideDeviceModal}>
                {t(TranslationKey.gotIt)}
              </ModalControl>
            </ModalControls>
          </ModalComponent>
        </Modal>
      )}

      {isSurveyModalVisible && <SurveyModal onClose={onCloseSurveyModal} />}

      {isTerminating && interior && (
        <ProjectSummary
          project={{
            interior,
            sceneWidth: sceneSize.width,
            sceneHeight: sceneSize.height,
            compositions,
          }}
          onClose={() => sendAbort(resetActiveCompositionId)}
          pxToCmRatio={pxToCmRatio}
        />
      )}
    </>
  );
};

export default GalleryBuilder;
