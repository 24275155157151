import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { LinkedButton } from '../Button';
import { TranslationKey } from '../../translations/types';

interface UseSurveyModalResult {
  isSurveyModalVisible: boolean;
  onShowSurveyModal: () => void;
  onCloseSurveyModal: () => void;
}

export const useSurveyModal = (): UseSurveyModalResult => {
  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);
  const onShowSurveyModal = useCallback(() => setIsSurveyModalVisible(true), []);
  const onCloseSurveyModal = useCallback(() => setIsSurveyModalVisible(false), []);

  const result = useMemo(
    () => ({ isSurveyModalVisible, onShowSurveyModal, onCloseSurveyModal }),
    [isSurveyModalVisible, onShowSurveyModal, onCloseSurveyModal],
  );

  return result;
};

const surveyLink = 'https://forms.gle/Qam9AKVchmC53LVA7';

const Container = styled.div`
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

const Title = styled.div`
  margin-bottom: 15px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.32;
  color: ${({ theme }) => theme.colors.black};
`;

const Button = styled(LinkedButton)`
  width: 100%;
  margin-bottom: 15px;
`;

const Hint = styled.div`
  font-size: 19px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.black};
`;

interface SurveyModalProps {
  onClose: () => void;
}

const SurveyModal: React.FC<SurveyModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose}>
      <Container>
        <Title>{t(TranslationKey.modalSurveyTitle)}</Title>

        <Button href={surveyLink} target="_blank" rel="noopener noreferrer">
          {t(TranslationKey.takeSurvey)}
        </Button>

        <Hint>{t(TranslationKey.surveyTime)}</Hint>
      </Container>
    </Modal>
  );
};

export default SurveyModal;
