import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import panzoom, { PanZoom } from 'panzoom';
import { useMutation } from 'react-query';
import { Formik } from 'formik';
import Button from '../Button';
import { TranslationKey } from '../../translations/types';
import ComposedField, { FieldProps as ComposedFieldProps } from '../ComposedField';
import Preloader from '../Preloader';
import {
  measurementControlSize,
  Point,
  getDistanceInPx,
  getPxToCmRatio,
  useMeasurements,
} from '../MeasurementsProvider';

const MeasurementControlContainer = styled.div`
  position: absolute;
  width: ${measurementControlSize}px;
  height: ${measurementControlSize}px;
`;

const MeasurementControlIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 5px;
    height: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.colors.alizarinCrimson};
    border-radius: 50%;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

interface TransformData {
  x: number;
  y: number;
  scale: number;
}

interface MeasurementControlProps {
  initialX: number;
  initialY: number;
  onTransform: (data: TransformData) => void;
}

const MeasurementControl: React.FC<MeasurementControlProps> = React.memo(({ initialX, initialY, onTransform }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const onActivateControl = useCallback(() => setIsActive(true), []);

  const onDeactivateControl = useCallback(() => setIsActive(false), []);

  const panzoomInstance = useRef<PanZoom>();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = containerRef.current;

    if (!node) return;

    panzoomInstance.current = panzoom(node, {
      smoothScroll: false,
      bounds: true,
      zoomDoubleClickSpeed: 1,
      maxZoom: 1,
      minZoom: 1,
    });

    panzoomInstance.current.moveTo(initialX, initialY);
    panzoomInstance.current.zoomTo(initialX, initialY, 1);

    panzoomInstance.current.on('transform', (event: PanZoom) => {
      const data = event.getTransform();

      onTransform(data);
    });

    panzoomInstance.current.pause();

    return () => {
      const instance = panzoomInstance.current;

      if (!instance) return;

      instance.dispose();
    };
  }, [containerRef, initialX, initialY, onTransform]);

  useEffect(() => {
    const instance = panzoomInstance.current;

    if (!instance) return;

    if (!isActive) {
      instance.pause();

      return;
    }

    instance.resume();
  }, [isActive, panzoomInstance]);

  return (
    <MeasurementControlContainer ref={containerRef} onPointerDown={onActivateControl} onPointerUp={onDeactivateControl}>
      <MeasurementControlIcon>
        <svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="nonzero" fill="none">
            <path
              d="m19.105 35.7-.021 2.035C12.614 36.543 7.506 31.459 6.281 25h2.041a14.018 14.018 0 0 0 10.783 10.7zm5.826 2.032.02-2.044a14.018 14.018 0 0 0 10.727-10.687h2.041a16.017 16.017 0 0 1-12.788 12.731zM6 21a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h5zm37 0a1 1 0 0 1 0 2h-5a1 1 0 0 1 0-2h5zm-5.28-2h-2.042A14.018 14.018 0 0 0 24.894 8.3l.021-2.035C31.386 7.456 36.495 12.54 37.719 19zM19.067 6.268l-.02 2.044A14.018 14.018 0 0 0 8.321 19H6.281A16.017 16.017 0 0 1 19.068 6.268z"
              fill="#FFF"
            />
            <path
              d="M22 37a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0v-5a1 1 0 0 1 1-1zm0-17.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM22 0a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1z"
              fill="#FE0409"
            />
          </g>
        </svg>
      </MeasurementControlIcon>
    </MeasurementControlContainer>
  );
});

const Controls = styled.div<{ heightOffset: number }>`
  position: absolute;
  width: 100%;
  height: ${({ heightOffset }) => `calc(100% - ${heightOffset}px);`};
  left: 0;
  bottom: 0;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 120px;
  padding: 0 10px 10px;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Header = styled.div`
  margin-bottom: 6px;
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 1.18;
  color: ${({ theme }) => theme.colors.mineShaft};
  text-align: center;
`;

const Form = styled.form`
  position: relative;
  display: flex;
  width: 240px;
  height: 38px;
  margin: 0 auto;
`;

const Field = styled.input<{ isError: boolean }>`
  display: flex;
  width: 137px;
  height: 100%;
  padding: 0 13px;
  font-size: 15px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.mineShaft};
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.shuttleGray};
  border-radius: 2px 0 0 2px;

  ${({ isError, theme }) =>
    isError &&
    `
    border-color: ${theme.colors.alizarinCrimson};
  `}
`;

const ErrorMessage = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.alizarinCrimson};
`;

const SubmitButton = styled(Button)`
  position: relative;
  width: 103px;
  height: 38px;
  font-size: 16px;
  border-radius: 0 2px 2px 0;
`;

const BackButton = styled.button`
  position: absolute;
  display: block;
  margin: 0;
  padding: 10px 5px 10px 20px;
  bottom: 15px;
  left: 20px;
  appearance: none;
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.mineShaft};
  border-radius: 50%;
  border: none;
  background: ${({ theme }) => theme.colors.white};

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-45deg);
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.mineShaft};
  }
`;

const SubmitButtonPreloader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
`;

const required = (value: any) => (value || typeof value === 'number' ? undefined : i18next.t('validations.required'));

const blur = () => {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
};

const useSaveData = () => {
  const mutateFn = () =>
    new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });

  return useMutation(mutateFn);
};

interface FormValues {
  distanceInCm: string;
}

interface MeasurementControlsWrapperProps {
  onBack: () => void;
  onSave: (pxToCmRatio: number) => void;
  point1InitialValue: Point;
  point2InitialValue: Point;
  distanceInCmInitialValue: number;
}

const MeasurementControlsWrapper: React.FC<MeasurementControlsWrapperProps> = ({
  onBack,
  onSave,
  point1InitialValue,
  point2InitialValue,
  distanceInCmInitialValue,
}) => {
  const { t } = useTranslation();

  const initialFormValues: FormValues = useMemo(
    () => ({ distanceInCm: distanceInCmInitialValue === 0 ? '' : toString(distanceInCmInitialValue) }),
    [distanceInCmInitialValue],
  );

  const [point1, setPoint1] = useState<Point>(point1InitialValue);

  const onChangePoint1 = useCallback((data: TransformData) => {
    setPoint1((prev) => (prev.x === data.x && prev.y === data.y ? prev : { x: data.x, y: data.y }));
  }, []);

  const [point2, setPoint2] = useState<Point>(point2InitialValue);

  const onChangePoint2 = useCallback((data: TransformData) => {
    setPoint2((prev) => (prev.x === data.x && prev.y === data.y ? prev : { x: data.x, y: data.y }));
  }, []);

  const distanceInPx = getDistanceInPx(point1, point2);

  const dataSavingState = useSaveData();

  const { onChangeMeasurements } = useMeasurements();

  const onSubmit = useCallback(
    (values: FormValues) => {
      blur();

      dataSavingState.mutate(undefined, {
        onSuccess: () => {
          const { distanceInCm } = values;

          const distanceInCmNumber = toNumber(distanceInCm);

          const pxToCmRatio = getPxToCmRatio({ distanceInPx, distanceInCm: distanceInCmNumber });

          onChangeMeasurements({
            point1,
            point2,
            distanceInCm: distanceInCmNumber,
          });

          onSave(pxToCmRatio);
        },
      });
    },
    [onSave, distanceInPx, dataSavingState, onChangeMeasurements, point1, point2],
  );

  return (
    <>
      <Container>
        <Header>
          <Title>{t(TranslationKey.measurementTitle)}</Title>
        </Header>

        <Formik initialValues={initialFormValues} onSubmit={onSubmit} enableReinitialize>
          {(formProps) => {
            return (
              <Form onSubmit={formProps.handleSubmit} noValidate>
                <ComposedField name="distanceInCm" validate={required}>
                  {(fieldProps: ComposedFieldProps) => (
                    <>
                      <Field
                        type="tel"
                        name={fieldProps.name}
                        value={fieldProps.value}
                        onChange={fieldProps.onChange}
                        onBlur={fieldProps.onBlur}
                        onFocus={fieldProps.onFocus}
                        placeholder={t('measurementPlaceholder')}
                        isError={fieldProps.error}
                      />

                      {fieldProps.error && <ErrorMessage>{fieldProps.errorMsg}</ErrorMessage>}
                    </>
                  )}
                </ComposedField>

                <SubmitButton type="submit" disabled={dataSavingState.isLoading}>
                  {dataSavingState.isIdle && t(TranslationKey.measurementSubmit)}

                  {dataSavingState.isLoading && (
                    <SubmitButtonPreloader>
                      <Preloader />
                    </SubmitButtonPreloader>
                  )}
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </Container>

      <BackButton type="button" onClick={onBack}>
        {t('goBack')}
      </BackButton>

      <MeasurementControl
        initialX={point1InitialValue.x}
        initialY={point1InitialValue.y}
        onTransform={onChangePoint1}
      />

      <MeasurementControl
        initialX={point2InitialValue.x}
        initialY={point2InitialValue.y}
        onTransform={onChangePoint2}
      />
    </>
  );
};

interface MeasurementControlsProps {
  onBack: () => void;
  onSave: (pxToCmRatio: number) => void;
  heightOffset: number;
  point1: Point;
  point2: Point;
  distanceInCm: number;
}

const MeasurementControls: React.FC<MeasurementControlsProps> = ({
  onBack,
  onSave,
  heightOffset,
  point1,
  point2,
  distanceInCm,
}) => {
  return (
    <Controls heightOffset={heightOffset}>
      <MeasurementControlsWrapper
        onBack={onBack}
        onSave={onSave}
        point1InitialValue={point1}
        point2InitialValue={point2}
        distanceInCmInitialValue={distanceInCm}
      />
    </Controls>
  );
};

export default MeasurementControls;
