import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AlertIcon from '../Icons/Alert';
import { TranslationKey } from '../../translations/types';

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 20px;
  text-align: center;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Header = styled.div``;

const Content = styled.div``;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h4`
  font-weight: normal;
  margin: 0 0 10px;
  padding: 0;
  font-size: 25px;
  line-height: 1.22;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

const Subtitle = styled.p`
  margin: 0 0 15px;
  padding: 0;
  font-size: 18px;
  line-height: 1.44;
  color: ${({ theme }) => theme.colors.scorpion};
`;

const Link = styled.a`
  font-size: 18px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

const Intro = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <Header>
          <Icon>
            <AlertIcon />
          </Icon>

          <Title>{t(TranslationKey.introTitle)}</Title>

          <Subtitle>{t(TranslationKey.introSubtitle)}</Subtitle>
        </Header>

        <Content>
          <Link href="https://t.me/AlenaSolo" target="_blank">
            {t(TranslationKey.introCta)}
          </Link>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Intro;
