import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useAnalytics } from '../AnalyticsProvider';
import useGallery from '../../hooks/useGallery';
import { DataExplorerScrollableContainer } from '../DataExplorer';
import Spinner from './Spinner';
import Error from './Error';
import { Gallery, GalleryItemOrientation, Poster } from '../../types';

const PostersListList = styled.div`
  display: flex;
  height: 100%;
`;

const PostersListItem = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
`;

const PostersListButton = styled.button<{ orientation: GalleryItemOrientation }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: transparent;
  box-shadow: 0 1px 4px 0 ${({ theme }) => rgba(theme.colors.black, 0.16)};

  ${({ orientation }) =>
    orientation === GalleryItemOrientation.PORTRAIT &&
    `
    width: 58px;
  `};

  ${({ orientation }) =>
    orientation === GalleryItemOrientation.LANDSCAPE &&
    `
    width: 83px;
    height: 58px;
  `};

  ${({ orientation }) =>
    orientation === GalleryItemOrientation.SQUARE &&
    `
    width: 58px;
    height: 58px;
  `};
`;

const GalleryThumbnail = styled.button`
  position: relative;
  display: inline-flex;
  width: 84px;
  height: 100%;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: transparent;
`;

interface PostersListProps {
  activeGallery: Gallery;
  onSelectPoster: (gallery: Gallery, poster: Poster) => void;
}

const PostersList: React.FC<PostersListProps> = ({ activeGallery, onSelectPoster }) => {
  const analyticsService = useAnalytics();

  const galleryState = useGallery(activeGallery.galleryId);

  const posters = useMemo(() => galleryState.data?.items ?? [], [galleryState]);

  const onClick = useCallback(() => {
    posters.forEach((poster) => {
      onSelectPoster(activeGallery, poster);

      analyticsService.event.selectPoster(poster.id);
    });
  }, [posters, activeGallery, analyticsService, onSelectPoster]);

  return (
    <DataExplorerScrollableContainer>
      {galleryState.isLoading && <Spinner />}

      {galleryState.isSuccess && (
        <PostersListList>
          <PostersListItem>
            <GalleryThumbnail
              type="button"
              style={{ backgroundImage: `url("${galleryState.data.thumbnail}")` }}
              aria-label={galleryState.data.title}
              onClick={onClick}
            />
          </PostersListItem>

          {galleryState.data.items.map((poster) => {
            const selectPoster = () => {
              onSelectPoster(activeGallery, poster);

              analyticsService.event.selectPoster(poster.id);
            };

            return (
              <PostersListItem key={poster.id}>
                <PostersListButton
                  type="button"
                  orientation={poster.orientation}
                  style={{ backgroundImage: `url("${poster.thumbnail}")` }}
                  aria-label={poster.title}
                  onClick={selectPoster}
                />
              </PostersListItem>
            );
          })}
        </PostersListList>
      )}

      {galleryState.isError && <Error />}
    </DataExplorerScrollableContainer>
  );
};

export default PostersList;
