import React from 'react';
import styled from 'styled-components';

const Button = styled.button<{ isDisabled: boolean }>`
  display: block;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
  `}
`;

const IconContainer = styled.div`
  display: flex;
  width: 26px;
  height: 25px;
  margin: 0 auto 4px;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
`;

interface ControlProps {
  title: string;
  Icon: React.FC;
  onClick: () => void;
  disabled?: boolean;
}

const Control: React.FC<ControlProps> = ({ title, Icon, onClick, disabled = false }) => {
  return (
    <Button type="button" isDisabled={disabled} onClick={onClick} disabled={disabled}>
      <IconContainer>
        <Icon />
      </IconContainer>

      <Title>{title}</Title>
    </Button>
  );
};

export default Control;
