import React, { useCallback } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import Scene from '../Scene';
import SceneComposition from '../SceneComposition';
import CompositionView from '../CompositionView';
import BackButton from '../BackButton';
import { Interior, NewComposition } from '../../types';
import { useAnalytics } from '../AnalyticsProvider';
import { LinkedButton } from '../Button';
import { TranslationKey } from '../../translations/types';

interface BuildUtmLinkProps {
  url: string;
  campaign: string;
  source: string;
  medium: string;
}

const buildUtmLink = ({ url, campaign, source, medium }: BuildUtmLinkProps): string => {
  const newUrl = `${url}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;

  return newUrl;
};

// const getFrameColor = (groupFrame?: GroupFrame): PosterFrameColor => {
//   if (!groupFrame) return 'no_frame';
//
//   switch (groupFrame) {
//     case GroupFrame.Black:
//       return 'black';
//
//     case GroupFrame.White:
//       return 'white';
//
//     case GroupFrame.Wooden:
//       return 'wood';
//
//     case GroupFrame.None:
//       return 'no_frame';
//   }
// };

interface ProjectSceneProps {
  project: Project;
  pxToCmRatio: number;
  isBare?: boolean;
}

const ProjectScene: React.FC<ProjectSceneProps> = ({ project, pxToCmRatio, isBare = false }) => (
  <Scene width={project.sceneWidth} height={project.sceneHeight} interior={project.interior} isDisabled theme="dark">
    {project.compositions.map((composition) => (
      <SceneComposition
        key={composition.id}
        id={composition.id}
        isActive={false}
        initialX={composition.x}
        initialY={composition.y}
        initialZoom={composition.scale}
      >
        <CompositionView
          id={composition.id}
          width={composition.size.width}
          height={composition.size.height}
          posterUrl={composition.poster.thumbnail}
          pxToCmRatio={pxToCmRatio}
          frameColor={composition.frame}
          isBare={isBare}
        />
      </SceneComposition>
    ))}
  </Scene>
);

const HeroContainer = styled.div`
  position: relative;
  padding: 0 0 35px;
`;

const HeroPreview = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  min-height: 315px;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
`;

interface HeroProps {
  project: Project;
  pxToCmRatio: number;
}

const Hero: React.FC<HeroProps> = ({ project, pxToCmRatio }) => {
  return (
    <HeroContainer>
      <HeroPreview width={project.sceneWidth}>
        <ProjectScene project={project} pxToCmRatio={pxToCmRatio} />
      </HeroPreview>
    </HeroContainer>
  );
};

const IntroContainer = styled.div`
  position: relative;
  padding: 0 25px 24px;
  color: ${({ theme }) => theme.colors.mineShaft};
  text-align: center;
`;

const IntroHeader = styled.div``;

const IntroTitle = styled.div`
  margin-bottom: 14px;
  font-size: 34px;
  line-height: 1.15;
`;

const IntroSubtitle = styled.div`
  font-size: 19px;
  line-height: 1.47;
  color: ${({ theme }) => theme.colors.scorpion};
  text-align: center;
`;

const Intro: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IntroContainer>
      <IntroHeader>
        <IntroTitle>{t(TranslationKey.interiorReady)}</IntroTitle>

        <IntroSubtitle>{t(TranslationKey.selectedProductsInfo)}</IntroSubtitle>
      </IntroHeader>
    </IntroContainer>
  );
};

const DetailsContainer = styled.div`
  position: relative;
  padding: 27px 20px 10px;
  background-color: ${({ theme }) => theme.colors.alabaster};
`;

const DetailsTitle = styled.div`
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

const DetailsList = styled.div``;

const DetailsItem = styled.a`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  text-decoration: none;
`;

const previewWidth = 84;

const DetailsItemPreview = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${previewWidth}px;

  img {
    display: block;
    width: 100%;
    max-height: 100%;
    box-shadow: 1px 1px 4px 0 ${({ theme }) => rgba(theme.colors.black, 0.16)};
  }
`;

const DetailsItemInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - ${previewWidth + 15}px);
  
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -15px;
    right: 0;
    background-color: ${({ theme }) => rgba(theme.colors.mineShaft, 0.1)}};
  }
`;

const DetailsItemContent = styled.div``;

const DetailsItemTitle = styled.div`
  margin: 0 0 2px;
  font-weight: normal;
  font-size: 17px;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.mineShaft};
  text-decoration: none;
`;

const DetailsItemSize = styled.div`
  margin-bottom: 17px;
  font-size: 16px;
  line-height: 1.47;
  color: ${({ theme }) => theme.colors.mineShaft};
  opacity: 0.8;
`;

const DetailsItemCta = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.mineShaft};
  text-transform: uppercase;

  &:after {
    ${({ theme }) => theme.arrow};
    width: 0.5em;
    height: 0.5em;
    top: -0.1em;
  }
`;

interface DetailsProps {
  project: Project;
}

const Details: React.FC<DetailsProps> = ({ project }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalytics();

  const onClick = useCallback(
    (productUrl: string) => {
      analyticsService.event.viewProduct(productUrl);
    },
    [analyticsService],
  );

  return (
    <DetailsContainer>
      <DetailsTitle>{t(TranslationKey.selectedProductsTitle)}</DetailsTitle>

      <DetailsList>
        {project.compositions.map((composition) => {
          const productLink = composition.poster.link;
          const link = buildUtmLink({
            url: productLink,
            campaign: 'intery_app',
            source: 'intery_app',
            medium: 'intery_app_cart',
          });

          return (
            <DetailsItem
              key={composition.id}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => onClick(productLink)}
            >
              <DetailsItemPreview>
                <img src={composition.poster.thumbnail} alt="" />
              </DetailsItemPreview>

              <DetailsItemInfo>
                <DetailsItemContent>
                  <DetailsItemTitle>{composition.poster.title}</DetailsItemTitle>

                  <DetailsItemSize>{`${composition.size.width}x${composition.size.height}`}</DetailsItemSize>

                  <DetailsItemCta>{t(TranslationKey.more)}</DetailsItemCta>
                </DetailsItemContent>
              </DetailsItemInfo>
            </DetailsItem>
          );
        })}
      </DetailsList>
    </DetailsContainer>
  );
};

const surveyLink = 'https://forms.gle/kmhdhLhUWrnBHsoN9';

const SurveyContainer = styled.div`
  padding: 25px 20px;
  text-align: center;
`;

const SurveyTitle = styled.div`
  margin-bottom: 15px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 19px;
  line-height: 1.32;
  color: ${({ theme }) => theme.colors.black};
`;

const SurveyButton = styled(LinkedButton)`
  display: inline-flex;
`;

const Survey = () => {
  const { t } = useTranslation();

  return (
    <SurveyContainer>
      <SurveyTitle>{t(TranslationKey.summarySurveyTitle)}</SurveyTitle>

      <SurveyButton href={surveyLink} target="_blank" rel="noopener noreferrer">
        {t(TranslationKey.takeSurvey)}
      </SurveyButton>
    </SurveyContainer>
  );
};

const ProjectSummaryContainer = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const ProjectSummaryWrapper = styled.div`
  max-width: 400px;
  min-width: 360px;
  margin: 0 auto;
`;

const BackButtonContainer = styled.div`
  padding: 15px 20px 20px;
`;

interface Project {
  interior: Interior;
  sceneWidth: number;
  sceneHeight: number;
  compositions: NewComposition[];
}

interface ProjectSummaryProps {
  project: Project;
  onClose: () => void;
  pxToCmRatio: number;
}

const ProjectSummary: React.FC<ProjectSummaryProps> = ({ project, onClose, pxToCmRatio }) => {
  const analyticsService = useAnalytics();

  const onBack = useCallback(() => {
    onClose();

    analyticsService.event.editProject();
  }, [onClose, analyticsService]);

  return (
    <ProjectSummaryContainer>
      <ProjectSummaryWrapper>
        <BackButtonContainer>
          <BackButton onClick={onBack} />
        </BackButtonContainer>

        <Intro />

        <Details project={project} />

        <Survey />

        <Hero project={project} pxToCmRatio={pxToCmRatio} />
      </ProjectSummaryWrapper>
    </ProjectSummaryContainer>
  );
};

export default ProjectSummary;
