import React, { useEffect, useMemo } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import GalleryBuilder from '../GalleryBuilder';
import Preloader from '../Preloader';
import TenantIdProvider, { useTenantId } from '../TenantIdProvider';
import TenantProvider from '../TenantProvider';
import useQuery from '../../hooks/useQuery';
import useTenant from '../../hooks/useTenant';
import { TranslationKey } from '../../translations/types';
import AnalyticsProvider, { useAnalytics } from '../AnalyticsProvider';
import Intro from '../Intro';
import useInterior from '../../hooks/useInterior';
import InteriorProvider from '../InteriorProvider';
import usePreselectedGallery from '../../hooks/usePreselectedGallery';
import PreselectedGalleryProvider from '../PreselectedGalleryProvider';
import MeasurementsProvider, {
  usePersistedMeasurements,
  measurementControlSize,
  horizontalOffset,
} from '../MeasurementsProvider';

const queryClient = new QueryClient();

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: 'TildaSans', Helvetica, Arial, serif;
    font-weight: normal;
    font-style: normal;
    background-color: ${(props) => props.theme.colors.white};
  }
  
  button {
    font-family: 'TildaSans', Helvetica, Arial, serif;
  }
`;

const Curtains = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${(props) => rgba(props.theme.colors.white, 0.8)};
`;

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const PreloaderContainer = styled.div`
  width: 50px;
  height: 50px;
`;

const ErrorMessage = styled.div`
  font-size: 20px;
  line-height: 1.4;
  color: ${(props) => props.theme.colors.mineShaft};
  text-align: center;
`;

interface Query {
  tenantId?: string;
}

interface AppContainerProps {
  children: React.ReactNode;
}

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const { pageview } = useAnalytics();
  const { pathname } = window.location;

  useEffect(() => {
    pageview(pathname);
  }, [pageview, pathname]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

interface AnalyticsContainerProps {
  children: React.ReactNode;
}

const AnalyticsContainer: React.FC<AnalyticsContainerProps> = ({ children }) => {
  const tenantState = useTenant();

  return <AnalyticsProvider trackingId={tenantState.data?.gaTrackingId}>{children}</AnalyticsProvider>;
};

interface TenantContainerProps {
  children: React.ReactNode;
}

const TenantContainer: React.FC<TenantContainerProps> = ({ children }) => {
  const tenantId = useTenantId();
  const tenantState = useTenant();
  const { t } = useTranslation();

  return (
    <>
      {tenantState.isLoading && (
        <Container>
          <PreloaderContainer>
            <Preloader info />
          </PreloaderContainer>
        </Container>
      )}

      {tenantState.isSuccess && (
        <TenantProvider tenant={tenantState.data}>
          {children}

          {!tenantId && <Curtains />}
        </TenantProvider>
      )}

      {tenantState.isError && (
        <Container>
          <ErrorMessage>{t(TranslationKey.settingsFetchError)}</ErrorMessage>
        </Container>
      )}
    </>
  );
};

interface InteriorContainerProps {
  children: React.ReactNode;
}

const InteriorContainer: React.FC<InteriorContainerProps> = ({ children }) => {
  const interiorState = useInterior();
  const { t } = useTranslation();

  return (
    <>
      {interiorState.isLoading && (
        <Container>
          <PreloaderContainer>
            <Preloader info />
          </PreloaderContainer>
        </Container>
      )}

      {interiorState.isSuccess && <InteriorProvider interior={interiorState.data}>{children}</InteriorProvider>}

      {interiorState.isError && (
        <Container>
          <ErrorMessage>{t(TranslationKey.interiorFetchError)}</ErrorMessage>
        </Container>
      )}
    </>
  );
};

interface PreselectedGalleryContainerProps {
  children: React.ReactNode;
}

const PreselectedGalleryContainer: React.FC<PreselectedGalleryContainerProps> = ({ children }) => {
  const { t } = useTranslation();
  const preselectedGalleryFetchingState = usePreselectedGallery();

  return (
    <>
      {preselectedGalleryFetchingState.isLoading && (
        <Container>
          <PreloaderContainer>
            <Preloader info />
          </PreloaderContainer>
        </Container>
      )}

      {preselectedGalleryFetchingState.isSuccess && (
        <PreselectedGalleryProvider gallery={preselectedGalleryFetchingState.data}>
          {children}
        </PreselectedGalleryProvider>
      )}

      {preselectedGalleryFetchingState.isError && (
        <Container>
          <ErrorMessage>{t(TranslationKey.preselectedGalleryFetchError)}</ErrorMessage>
        </Container>
      )}
    </>
  );
};

interface MeasurementsContainerProps {
  children: React.ReactNode;
}

const MeasurementsContainer: React.FC<MeasurementsContainerProps> = ({ children }) => {
  const persistedMeasurementsFetchingState = usePersistedMeasurements();

  const windowWidth = window.innerWidth ?? 0;
  const windowHeight = window.innerHeight ?? 0;

  const point1 = useMemo(
    () => ({
      x: Math.round(windowWidth / 2) - Math.round(measurementControlSize / 2) - horizontalOffset,
      y: Math.round(windowHeight / 2) - Math.round(measurementControlSize / 2),
    }),
    [windowWidth, windowHeight],
  );

  const point2 = useMemo(
    () => ({
      x: Math.round(windowWidth / 2) - Math.round(measurementControlSize / 2) + horizontalOffset,
      y: Math.round(windowHeight / 2) - Math.round(measurementControlSize / 2),
    }),
    [windowWidth, windowHeight],
  );

  const defaultMeasurements = useMemo(
    () => ({
      point1,
      point2,
      distanceInCm: 0,
    }),
    [point1, point2],
  );

  return (
    <>
      {persistedMeasurementsFetchingState.isLoading && (
        <Container>
          <PreloaderContainer>
            <Preloader info />
          </PreloaderContainer>
        </Container>
      )}

      {persistedMeasurementsFetchingState.isSuccess && (
        <MeasurementsProvider measurements={persistedMeasurementsFetchingState.data}>{children}</MeasurementsProvider>
      )}

      {persistedMeasurementsFetchingState.isError && (
        <MeasurementsProvider measurements={defaultMeasurements}>{children}</MeasurementsProvider>
      )}
    </>
  );
};

const RootContainer: React.FC = () => {
  const { tenantId = '' } = useQuery<Query>();

  return (
    <QueryClientProvider client={queryClient}>
      <TenantIdProvider tenantId={tenantId}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />

          {!!tenantId && (
            <TenantContainer>
              <AnalyticsContainer>
                <AppContainer>
                  <InteriorContainer>
                    <PreselectedGalleryContainer>
                      <MeasurementsContainer>
                        <GalleryBuilder />
                      </MeasurementsContainer>
                    </PreselectedGalleryContainer>
                  </InteriorContainer>
                </AppContainer>
              </AnalyticsContainer>
            </TenantContainer>
          )}

          {!tenantId && <Intro />}
        </ThemeProvider>
      </TenantIdProvider>
    </QueryClientProvider>
  );
};

export default RootContainer;
