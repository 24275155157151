import React from 'react';
import styled from 'styled-components';
import { GroupFrame, getHexColorByFrame } from '../../types';

const getFrameColor = (frameColor?: GroupFrame): string => {
  if (!frameColor) return 'transparent';

  const result = getHexColorByFrame(frameColor);

  return result;
};

const offsetValue = 6;
const frameWidth = 2;
const filterId = 'f9a58c1f-e628-4010-90ef-6acf0ef9eaaa';

const GroupContainer = styled.g``;

const GroupBackground = styled.rect<{ hasBorders: boolean }>`
  fill: ${({ theme }) => theme.colors.white};

  ${({ hasBorders, theme }) =>
    hasBorders &&
    `
    stroke: ${theme.colors.dustyGray};
    stroke-width: 1px;
  `};
`;

export const getCompositionPxSize = (length: number, pxToCmRatio: number): number => {
  const result = Math.ceil(length * pxToCmRatio);

  return result;
};

const getFramePxWidth = (pxToCmRatio: number): number => {
  const result = Math.ceil(pxToCmRatio + frameWidth);

  return result;
};

interface CompositionViewProps {
  id: string;
  width: number;
  height: number;
  posterUrl: string;
  pxToCmRatio: number;
  frameColor?: GroupFrame;
  hasBorders?: boolean;
  isBare?: boolean;
}

const CompositionView: React.FC<CompositionViewProps> = ({
  id,
  width,
  height,
  posterUrl,
  pxToCmRatio,
  frameColor,
  hasBorders = false,
  isBare = false,
}) => {
  const initialSvgWidth = getCompositionPxSize(width, pxToCmRatio);
  const initialSvgHeight = getCompositionPxSize(height, pxToCmRatio);

  const svgWidth = initialSvgWidth + offsetValue * 2;
  const svgHeight = initialSvgHeight + offsetValue * 2;

  const groupWidth = initialSvgWidth + frameWidth;
  const groupHeight = initialSvgHeight + frameWidth;
  const groupOffset = (frameWidth / 2) * -1;

  const frameHexColor = getFrameColor(frameColor);

  const hasFrame = !isBare && frameColor;

  return (
    <svg data-id={id} width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
      <defs>
        <React.Fragment key={id}>
          <rect
            id={id}
            width={groupWidth}
            height={groupHeight}
            transform={`translate(${groupOffset} ${groupOffset})`}
          />

          <filter
            id={`${filterId}-${id}`}
            width="108.4%"
            height="106%"
            x="-3.2%"
            y="-2.2%"
            filterUnits="objectBoundingBox"
          >
            <feMorphology in="SourceAlpha" operator="dilate" radius="2" result="shadowSpreadOuter1" />
            <feOffset dx="1" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feMorphology in="SourceAlpha" radius="2" result="shadowInner" />
            <feOffset dx="1" dy="1" in="shadowInner" result="shadowInner" />
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1" />
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" />
          </filter>
        </React.Fragment>
      </defs>

      <g transform={`translate(${offsetValue} ${offsetValue})`}>
        <GroupContainer key={id} fill="none" fillRule="evenodd">
          <GroupBackground width={initialSvgWidth} height={initialSvgHeight} hasBorders={hasBorders} />

          {!!posterUrl && !isBare && (
            <image
              href={posterUrl}
              xlinkHref={posterUrl}
              width={initialSvgWidth}
              height={initialSvgHeight}
              preserveAspectRatio="none"
            />
          )}

          {hasFrame && (
            <>
              <use fill="#000" filter={`url(#${filterId}-${id})`} xlinkHref={`#${id}`} />
              <use stroke={frameHexColor} strokeWidth={getFramePxWidth(pxToCmRatio)} xlinkHref={`#${id}`} />
            </>
          )}
        </GroupContainer>
      </g>
    </svg>
  );
};

export default CompositionView;
