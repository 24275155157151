import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';
import { useCallback, useMemo, useState } from 'react';

interface BuildGalleryBuilderMachineProps {
  isAnyInteriorExists: boolean;
}

const buildGalleryBuilderMachine = ({ isAnyInteriorExists }: BuildGalleryBuilderMachineProps) => {
  const initialState = isAnyInteriorExists ? 'exploring' : 'idle';

  return createMachine({
    schema: {
      events: {} as
        | { type: 'UPLOAD_INTERIOR' }
        | { type: 'EDIT_INTERIOR' }
        | { type: 'SAVE_INTERIOR' }
        | { type: 'SAVE_MEASUREMENT' }
        | { type: 'VIEW_FRAMES' }
        | { type: 'VIEW_POSTERS' }
        | { type: 'SAVE_PROJECT' }
        | { type: 'CLOSE_FRAMES' }
        | { type: 'CLOSE_POSTERS' }
        | { type: 'ABORT' },
    },
    id: 'galleryBuilder',
    initial: initialState,
    states: {
      idle: {
        on: { UPLOAD_INTERIOR: 'editingInterior' },
      },
      editingInterior: {
        on: { SAVE_INTERIOR: 'measuring' },
      },
      measuring: {
        on: { SAVE_MEASUREMENT: 'exploring', EDIT_INTERIOR: 'editingInterior' },
      },
      exploring: {
        on: {
          VIEW_FRAMES: 'framing',
          VIEW_POSTERS: 'selectingPoster',
          SAVE_PROJECT: 'terminating',
          EDIT_INTERIOR: 'editingInterior',
        },
      },
      framing: {
        on: { CLOSE_FRAMES: 'exploring' },
      },
      selectingPoster: {
        on: { CLOSE_POSTERS: 'exploring' },
      },
      terminating: {
        on: { ABORT: 'exploring' },
      },
    },
  });
};

type TransitionCallbackFn = () => void;

type TransitionFn = (callback?: TransitionCallbackFn) => void;

interface UseBuilderStateResult {
  isIdle: boolean;
  isEditingInterior: boolean;
  isMeasuring: boolean;
  isExploring: boolean;
  isFraming: boolean;
  isSelectingPoster: boolean;
  isTerminating: boolean;

  sendUploadInterior: TransitionFn;
  sendEditInterior: TransitionFn;
  sendSaveInterior: TransitionFn;
  sendSaveMeasurement: TransitionFn;
  sendViewFrames: TransitionFn;
  sendViewPosters: TransitionFn;
  sendSaveProject: TransitionFn;
  sendCloseFrames: TransitionFn;
  sendClosePosters: TransitionFn;
  sendAbort: TransitionFn;
}

interface UseBuilderStateProps {
  isAnyInteriorExists: boolean;
}

const useBuilderState = ({ isAnyInteriorExists }: UseBuilderStateProps): UseBuilderStateResult => {
  // https://github.com/statelyai/xstate/issues/1101#issuecomment-1226923997
  const [initialState] = useState(() => buildGalleryBuilderMachine({ isAnyInteriorExists }));

  const [state, send] = useMachine(initialState);

  const isIdle = state.matches('idle');
  const isEditingInterior = state.matches('editingInterior');
  const isMeasuring = state.matches('measuring');
  const isExploring = state.matches('exploring');
  const isFraming = state.matches('framing');
  const isSelectingPoster = state.matches('selectingPoster');
  const isTerminating = state.matches('terminating');

  const sendUploadInterior: TransitionFn = useCallback(
    (callback) => {
      send('UPLOAD_INTERIOR');

      callback?.();
    },
    [send],
  );
  const sendEditInterior: TransitionFn = useCallback(
    (callback) => {
      send('EDIT_INTERIOR');

      callback?.();
    },
    [send],
  );
  const sendSaveInterior: TransitionFn = useCallback(
    (callback) => {
      send('SAVE_INTERIOR');

      callback?.();
    },
    [send],
  );
  const sendSaveMeasurement: TransitionFn = useCallback(
    (callback) => {
      send('SAVE_MEASUREMENT');

      callback?.();
    },
    [send],
  );
  const sendViewFrames: TransitionFn = useCallback(
    (callback) => {
      send('VIEW_FRAMES');

      callback?.();
    },
    [send],
  );
  const sendViewPosters: TransitionFn = useCallback(
    (callback) => {
      send('VIEW_POSTERS');

      callback?.();
    },
    [send],
  );
  const sendSaveProject: TransitionFn = useCallback(
    (callback) => {
      send('SAVE_PROJECT');

      callback?.();
    },
    [send],
  );
  const sendCloseFrames: TransitionFn = useCallback(
    (callback) => {
      send('CLOSE_FRAMES');

      callback?.();
    },
    [send],
  );
  const sendClosePosters: TransitionFn = useCallback(
    (callback) => {
      send('CLOSE_POSTERS');

      callback?.();
    },
    [send],
  );
  const sendAbort: TransitionFn = useCallback(
    (callback) => {
      send('ABORT');

      callback?.();
    },
    [send],
  );

  const result = useMemo(
    () => ({
      isIdle,
      isEditingInterior,
      isMeasuring,
      isExploring,
      isFraming,
      isSelectingPoster,
      isTerminating,
      sendUploadInterior,
      sendEditInterior,
      sendSaveInterior,
      sendSaveMeasurement,
      sendViewFrames,
      sendViewPosters,
      sendSaveProject,
      sendCloseFrames,
      sendClosePosters,
      sendAbort,
    }),
    [
      isIdle,
      isEditingInterior,
      isMeasuring,
      isExploring,
      isFraming,
      isSelectingPoster,
      isTerminating,
      sendUploadInterior,
      sendEditInterior,
      sendSaveInterior,
      sendSaveMeasurement,
      sendViewFrames,
      sendViewPosters,
      sendSaveProject,
      sendCloseFrames,
      sendClosePosters,
      sendAbort,
    ],
  );

  return result;
};

export default useBuilderState;
