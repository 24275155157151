import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import round from 'lodash/round';
import { Interior, InteriorOrientation, InteriorTransform } from '../../types';
import usePanZoom from './usePanZoom';
import usePanZoomPause from './usePanZoomPause';
import usePanZoomInitialPosition from './usePanZoomInitialPosition';

export type InteriorEditingState = 'active' | 'disabled';

const getReductionRatio = (originalValue: number, reducedValue: number): number => {
  const result = round(originalValue / reducedValue, 2);

  return result;
};

const reduceSize = (size: number, reductionRatio: number): number => {
  if (size === 0) return 0;

  const result = round(size / reductionRatio, 0);

  return result;
};

const Container = styled.div<{
  width: number;
  height: number;
  background?: string;
  backgroundOrientation: InteriorOrientation;
  isDisabled: boolean;
}>`
  position: absolute;
  appearance: none;
  outline: none;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
  `};

  ${({ backgroundOrientation, height }) =>
    backgroundOrientation === InteriorOrientation.Landscape &&
    `
    top: 50%;
    margin-top: ${round(height / 2) * -1}px;
  `};

  ${({ backgroundOrientation, width }) =>
    backgroundOrientation === InteriorOrientation.Portrait &&
    `
    left: 50%;
    margin-left: ${round(width / 2) * -1}px;
  `};

  img {
    display: block;
    width: auto;
    height: auto;

    ${({ backgroundOrientation, width }) =>
      backgroundOrientation === InteriorOrientation.Landscape &&
      `
      width: ${width}px;
    `};

    ${({ backgroundOrientation, height }) =>
      backgroundOrientation === InteriorOrientation.Portrait &&
      `
      height: ${height}px;
    `};
  }
`;

interface BackgroundProps {
  width: number;
  height: number;
  interior: Interior;
  initialX?: number;
  initialY?: number;
  initialZoom?: number;
  interiorEditingState?: InteriorEditingState;
  onTransformInterior?: (data: InteriorTransform) => void;
}

const Background: React.FC<BackgroundProps> = ({
  width,
  height,
  interior,
  initialX = 0,
  initialY = 0,
  initialZoom = 1,
  onTransformInterior,
  interiorEditingState = 'disabled',
}) => {
  const backgroundRef = useRef<HTMLDivElement>(null);

  const isInteriorEditable = interiorEditingState === 'active';

  const interiorOrientation = useMemo(() => interior.orientation, [interior]);
  const interiorOriginalWidth = useMemo(() => interior.originalSize.width, [interior]);
  const interiorOriginalHeight = useMemo(() => interior.originalSize.height, [interior]);

  const backgroundSize = useMemo(() => {
    if (interiorOrientation === InteriorOrientation.Landscape) {
      const reductionRatio = getReductionRatio(interiorOriginalWidth, width);
      const size = { width, height: reduceSize(interiorOriginalHeight, reductionRatio) };

      return size;
    }

    const reductionRatio = getReductionRatio(interiorOriginalHeight, height);
    const size = { width: reduceSize(interiorOriginalWidth, reductionRatio), height };

    return size;
  }, [interiorOrientation, interiorOriginalWidth, interiorOriginalHeight, width, height]);

  const panzoomInstance = usePanZoom({ node: backgroundRef.current, onTransform: onTransformInterior });

  usePanZoomPause({ instance: panzoomInstance, isActive: isInteriorEditable });

  usePanZoomInitialPosition({
    instance: panzoomInstance,
    initialX,
    initialY,
    initialZoom,
  });

  return (
    <Container
      ref={backgroundRef}
      width={backgroundSize.width}
      height={backgroundSize.height}
      background={interior.preview}
      backgroundOrientation={interior.orientation}
      isDisabled={!isInteriorEditable}
    >
      <img src={interior.preview} alt="" />
    </Container>
  );
};

export default Background;
