import localForage from 'localforage';
import { Interior } from '../types';

const dbKey = 'gallery-builder-db';

const interiorKey = `${dbKey}-interior-v2`;

export const interior = {
  async set(interiorItem: Interior): Promise<void> {
    await localForage.setItem(interiorKey, interiorItem);
  },

  async get(): Promise<Interior | undefined> {
    const interiorItem: Interior | undefined | null = await localForage.getItem(interiorKey);

    if (!interiorItem) return undefined;

    return interiorItem;
  },
};

const measurementsKey = `${dbKey}-measurements-v2`;

export const measurements = {
  async set(newMeasurements: string): Promise<void> {
    await localForage.setItem(measurementsKey, newMeasurements);
  },

  async get(): Promise<unknown> {
    const result = await localForage.getItem(measurementsKey);

    return result;
  },
};

export const test = 'test';
