import { PanZoom } from 'panzoom';
import { useEffect } from 'react';

interface UsePanZoomPauseProps {
  instance?: PanZoom;
  isActive: boolean;
}

const usePanZoomPause = ({ instance, isActive }: UsePanZoomPauseProps) => {
  useEffect(() => {
    if (!instance) return;

    if (!isActive) {
      instance.pause();

      return;
    }

    instance.resume();
  }, [instance, isActive]);
};

export default usePanZoomPause;
