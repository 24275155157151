import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ControlComponent from '../Control';
import InteriorPosterIcon from '../Icons/InteriorPoster';
import { TranslationKey } from '../../translations/types';
import InteriorFrameIcon from '../Icons/InteriorFrame';
import InteriorEditIcon from '../Icons/InteriorEdit';

const Controls = styled.div<{ controlsHeight: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ controlsHeight }) => `${controlsHeight}px`};
  padding: 0 10px;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 44px;
`;

const Control = styled.div`
  margin-bottom: 5px;
`;

interface BuilderControlsProps {
  isPostersControlDisabled: boolean;
  onPostersControlClick: () => void;

  isFramesControlDisabled: boolean;
  onFramesControlClick: () => void;

  isInteriorControlDisabled: boolean;
  onInteriorControlClick: () => void;

  controlsHeight: number;
}

const BuilderControls: React.FC<BuilderControlsProps> = ({
  isPostersControlDisabled,
  onPostersControlClick,
  isFramesControlDisabled,
  onFramesControlClick,
  isInteriorControlDisabled,
  onInteriorControlClick,
  controlsHeight,
}) => {
  const { t } = useTranslation();

  return (
    <Controls controlsHeight={controlsHeight}>
      <ControlsContainer>
        <Control>
          <ControlComponent
            Icon={InteriorPosterIcon}
            title={t(TranslationKey.posters)}
            onClick={onPostersControlClick}
            disabled={isPostersControlDisabled}
          />
        </Control>

        <Control>
          <ControlComponent
            Icon={InteriorFrameIcon}
            title={t(TranslationKey.frames)}
            onClick={onFramesControlClick}
            disabled={isFramesControlDisabled}
          />
        </Control>

        <Control>
          <ControlComponent
            Icon={InteriorEditIcon}
            title={t(TranslationKey.interior)}
            onClick={onInteriorControlClick}
            disabled={isInteriorControlDisabled}
          />
        </Control>
      </ControlsContainer>
    </Controls>
  );
};

export default BuilderControls;
