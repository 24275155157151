import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import panzoom, { PanZoom } from 'panzoom';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { CompositionTransform } from '../../types';
import CloseButton from '../CloseButton';
import EditButton from '../EditButton';
import { TranslationKey } from '../../translations/types';

const Container = styled.div<{ isActive: boolean; isHidden?: boolean }>`
  position: absolute;
  display: inline-flex;
  margin: 0;
  padding: 5px;
  appearance: none;
  outline: none;
  background: transparent;
  border: 2px solid transparent;

  ${({ isActive, theme }) =>
    isActive &&
    `
    border-color: ${theme.colors.blueRibbon};
  `};

  ${({ isHidden }) =>
    isHidden &&
    `
    visibility: hidden;
    pointer-events: none;
  `};
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
`;

const EditButtonContainer = styled.div`
  position: absolute;
  bottom: -20px;
  left: -20px;
`;

enum TransformingState {
  Idle = 'IDLE',
  Transforming = 'TRANSFORMING',
}

interface SceneCompositionProps {
  id: string;
  isActive: boolean;
  isHidden?: boolean;
  initialX?: number;
  initialY?: number;
  initialZoom?: number;
  maxZoom?: number;
  minZoom?: number;
  onActivate?: (id: string) => void;
  onRemove?: (compositionId: string) => void;
  onTransform?: (compositionId: string, data: CompositionTransform) => void;
  onEdit?: () => void;
  children: React.ReactNode;
}

const SceneComposition: React.FC<SceneCompositionProps> = ({
  id,
  isActive,
  isHidden,
  initialX = 0,
  initialY = 0,
  initialZoom = 1,
  maxZoom,
  minZoom,
  children,
  onActivate,
  onRemove,
  onTransform,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [transformingState, setTransformingState] = useState(TransformingState.Idle);
  const onStartTransform = useCallback(() => setTransformingState(TransformingState.Transforming), []);
  const onFinishTransform = useCallback(() => setTransformingState(TransformingState.Idle), []);
  const onDebouncedFinishTransform = useMemo(() => debounce(onFinishTransform, 200), [onFinishTransform]);

  const isTransforming = transformingState === TransformingState.Transforming;

  const activateComposition = useCallback(() => {
    if (!onActivate) return;

    onActivate(id);
  }, [id, onActivate]);

  const remove = useCallback(() => {
    if (!onRemove) return;

    if (isTransforming) return;

    onRemove(id);
  }, [id, onRemove, isTransforming]);

  const edit = useCallback(() => {
    if (!onEdit) return;

    if (isTransforming) return;

    onEdit();
  }, [onEdit, isTransforming]);

  const containerRef = useRef<HTMLDivElement>(null);

  const panzoomInstance = useRef<PanZoom>();

  useEffect(() => {
    const node = containerRef.current;

    if (!node) return;

    panzoomInstance.current = panzoom(node, {
      smoothScroll: false,
      bounds: true,
      zoomDoubleClickSpeed: 1,
      maxZoom,
      minZoom,
    });

    panzoomInstance.current.moveTo(initialX, initialY);
    panzoomInstance.current.zoomTo(initialX, initialY, initialZoom);

    panzoomInstance.current.on('transform', (event: PanZoom) => {
      if (!onTransform) return;

      onStartTransform();

      const data = event.getTransform();

      onTransform(id, data);

      onDebouncedFinishTransform();
    });

    return () => {
      const instance = panzoomInstance.current;

      if (!instance) return;

      instance.dispose();
    };
  }, [
    containerRef,
    id,
    initialX,
    initialY,
    initialZoom,
    onTransform,
    onStartTransform,
    onDebouncedFinishTransform,
    maxZoom,
    minZoom,
  ]);

  useEffect(() => {
    const instance = panzoomInstance.current;

    if (!instance) return;

    if (!isActive) {
      instance.pause();

      return;
    }

    instance.resume();
  }, [isActive, panzoomInstance]);

  return (
    <Container
      data-id={id}
      isActive={isActive}
      ref={containerRef}
      onPointerDown={activateComposition}
      onTouchEnd={activateComposition}
      isHidden={isHidden}
    >
      {children}

      {isActive && (
        <>
          <CloseButtonContainer>
            <CloseButton onClick={remove} label={t(TranslationKey.remove)} size="small" />
          </CloseButtonContainer>

          <EditButtonContainer>
            <EditButton onClick={edit} label={TranslationKey.edit} size="small" />
          </EditButtonContainer>
        </>
      )}
    </Container>
  );
};

export default SceneComposition;
